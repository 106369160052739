import { z } from 'zod';
import { zDecimalNullable, zDecimalRequired } from '@harvestiq/zod';
import { LivestockInsuranceTypes } from '@harvestiq/constants';
import { zIsoDate } from '@harvestiq/zod';
import { coverageReminderLgmMarketingMonthSchema } from './coverage-reminders';

export const lgmMarketingsSchema = z.object({
  grossMarginGuarantee: zDecimalRequired(),
  numberOfHead: z.number(),
  formattedMarketingMonth: z.string(), // formatted month and year, eg. July 2024
});

const commonSchema = {
  commodity: z.string(),
  commodityType: z.string(),
  endDate: zIsoDate().nullable(), // null for LGM
  insuredName: z.string(),
  salesEffectiveDate: zIsoDate(),
};

export const lrpCoverageTableSchema = z.object({
  type: z.literal(LivestockInsuranceTypes.LRP),
  coverageLevel: z.number(),
  coveragePricePerCwt: z.number(),
  coveragePricePerHead: z.number(),
  targetWeight: z.coerce.number(),
  endorsementLength: z.number(),
  numberOfHead: z.coerce.number(),
  ...commonSchema,
});

export const lgmCoverageTableSchema = z.object({
  type: z.literal(LivestockInsuranceTypes.LGM),
  deductible: z.number(),
  liveCattleTargetCwtPerHead: z.number().nullable(),
  feederCattleTargetCwtPerHead: z.number().nullable(),
  cornTargetBuPerHead: z.number().nullable(),
  marketingMonths: z.array(lgmMarketingsSchema),
  ...commonSchema,
});

export const coverageDetailsEmailTableSchema = z.discriminatedUnion('type', [
  lrpCoverageTableSchema,
  lgmCoverageTableSchema,
]);
export type CoverageDetailsEmailTable = z.infer<
  typeof coverageDetailsEmailTableSchema
>;
