import {
  createContext,
  useCallback,
  useContext,
  PropsWithChildren,
} from 'react'
import { useNavigate, NavigateOptions } from 'react-router-dom'
import { useLocalStorage } from '../../hooks/useLocalStorage'

interface RedirectContextType {
  value: string | undefined
  setRedirect: (redirectPath: string) => void
  redirectOrNavigate: (to?: string, options?: NavigateOptions) => void
}

export const useProvideRedirect = (): RedirectContextType => {
  const { value, setValue, removeValue } = useLocalStorage<string>('redirect')
  const navigate = useNavigate()

  const setRedirect = useCallback(
    (redirectPath: string) => {
      setValue(redirectPath)
    },
    [setValue],
  )

  const redirectOrNavigate = useCallback(
    (to?: string, options?: NavigateOptions) => {
      if (value) {
        navigate(value, { replace: true })
      } else if (to) {
        navigate(to, options)
      }
      removeValue()
    },
    [value, removeValue, navigate],
  )

  return { value, setRedirect, redirectOrNavigate }
}

const RedirectContext = createContext<RedirectContextType>(
  {} as RedirectContextType,
)
const { Provider } = RedirectContext

export const RedirectProvider = ({ children }: PropsWithChildren) => {
  const useRedirectContext = useProvideRedirect()
  return <Provider value={useRedirectContext}>{children}</Provider>
}

export const useRedirect = (): RedirectContextType =>
  useContext(RedirectContext)
