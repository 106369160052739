import { z } from 'zod';
import { zDecimalNullable, zDecimalRequired } from '@harvestiq/zod';
import { InsureIQPolicyTypes } from '@harvestiq/constants';
import { getCoveragesRequestSchema } from './get-coverages';

export const getCoveragesReportRequestSchema = getCoveragesRequestSchema
  .pick({
    typeCodes: true,
    entityIds: true,
    salesEffectiveDates: true,
    userOwnerIds: true,
    order: true,
    orderBy: true,
    statuses: true,
  })
  .extend({
    type: z.nativeEnum(InsureIQPolicyTypes),
  });
export type GetCoveragesReportRequest = z.infer<
  typeof getCoveragesReportRequestSchema
>;

export const insureIQEmailCoveragesReportRequestSchema = z.object({
  ccMe: z.boolean(),
  emailNotes: z.string(),
  s3FileKey: z.string(),
  recipients: z.array(
    z.object({
      email: z.string().email(),
      name: z.string(),
    })
  ),
  type: z.nativeEnum(InsureIQPolicyTypes),
  sendPreview: z.boolean().optional(),
});

export type InsureIQEmailCoveragesReportRequest = z.infer<
  typeof insureIQEmailCoveragesReportRequestSchema
>;

export const insureIQEmailCoveragesReportSchema = z.object({
  emailNotes: z.string(),
  s3FileKey: z.string(),
  fileUrl: z.string(),
  type: z.nativeEnum(InsureIQPolicyTypes),
  insured: z.string(),
});

export type InsureIQEmailCoveragesReport = z.infer<
  typeof insureIQEmailCoveragesReportSchema
>;

export const insureIQEmailCoveragesReportResponseSchema = z.object({
  data: z.array(insureIQEmailCoveragesReportSchema),
});

export type InsureIQEmailCoveragesReportResponse = z.infer<
  typeof insureIQEmailCoveragesReportResponseSchema
>;

export const coveragesDetailedWorksheetSchema = z.object({
  commodity: z.string(),
  numberofHead: z.number(),
  targetWeight: z.number(),
  salesEffectiveDate: z.string(),
  endDate: z.string(),
  totalCoveragePrice: z.number(),
  totalPremium: z.number(),
  totalNetCoveragePrice: z.number(),
  totalProjectedOrActualRevenue: z.number().nullable(),
  totalProjectedIndemnity: z.number().nullable(),
  totalNetProjectedIndemnity: z.number().nullable(),
});

export type CoveragesDetailedWorksheet = z.infer<
  typeof coveragesDetailedWorksheetSchema
>;
export const coveragesAggregatedWorksheetSchema =
  coveragesDetailedWorksheetSchema
    .pick({
      commodity: true,
      numberofHead: true,
      totalCoveragePrice: true,
      totalPremium: true,
      totalNetCoveragePrice: true,
      totalProjectedOrActualRevenue: true,
      totalProjectedIndemnity: true,
      totalNetProjectedIndemnity: true,
    })
    .extend({
      endMonth: z.string(),
      numberOfEndorsements: z.number().optional(),
      totalTargetWeight: z.number(),
    });

export type CoveragesAggregatedWorksheet = z.infer<
  typeof coveragesAggregatedWorksheetSchema
>;

export const lrpCoveragesReportKpisSchema = z.object({
  totalEndorsements: zDecimalRequired(),
  totalHeadcount: zDecimalRequired(),
  totalWeightCwt: zDecimalRequired(),
  totalInsuredValue: zDecimalRequired(),
  totalProjectedRevenue: zDecimalRequired(),
  totalProjectedIndemnity: zDecimalRequired(),
  totalProducerPremium: zDecimalRequired(),
  totalNetProjectedIndemnity: zDecimalRequired(),
});

export type LrpCoveragesReportKpis = z.infer<
  typeof lrpCoveragesReportKpisSchema
>;

export const getLrpCoveragesReportResponseSchema = z.object({
  kpis: lrpCoveragesReportKpisSchema,
  s3FileKey: z.string(),
});
export type GetLrpCoveragesReportResponse = z.infer<
  typeof getLrpCoveragesReportResponseSchema
>;

export const lgmCoveragesReportEndorsementSchema = z.object({
  salesEffectiveDate: z.string(),
  commodity: z.string(),
  marketingMonth: z.string(),
  totalEndorsements: z.number().int().optional(),
  deductible: z.number().int().optional(),
  totalTargetMarketings: z.number().int(),
  actualsPublished: z.boolean(),
  totalExpectedGrossMargin: zDecimalRequired(),
  totalGrossMarginGuarantee: zDecimalRequired(),
  totalProjectedOrActualGrossMargin: zDecimalRequired(),
  totalBasePremium: zDecimalRequired(),
  totalSubsidy: zDecimalRequired(),
  totalProducerPremium: zDecimalRequired(),
  totalGrossMarginChange: zDecimalRequired(),
  totalProjectedIndemnity: zDecimalNullable(), // only use for Total row
  totalNetProjectedIndemnity: zDecimalNullable(), // only use for Total row
  totalAllocatedActualIndemnity: zDecimalNullable().optional(),
  totalAllocatedNetActualIndemnity: zDecimalNullable().optional(),
  totalAllocatedProjectedIndemnity: zDecimalNullable().optional(),
  totalAllocatedNetProjectedIndemnity: zDecimalNullable().optional(),
});
export type LgmCoveragesReportEndorsement = z.infer<
  typeof lgmCoveragesReportEndorsementSchema
>;

export const lgmCoveragesReportKpisSchema = z.object({
  totalEndorsements: z.number().int(),
  totalTargetMarketings: z.number().int(),

  totalExpectedGrossMargin: zDecimalRequired(),
  totalGrossMarginGuarantee: zDecimalRequired(),
  totalProjectedOrActualGrossMargin: zDecimalRequired(),
  totalBasePremium: zDecimalRequired(),
  totalSubsidy: zDecimalRequired(),
  totalProducerPremium: zDecimalRequired(),
  totalNetProducerPremium: zDecimalRequired(),
  totalProjectedIndemnity: zDecimalRequired(),
  totalNetProjectedIndemnity: zDecimalRequired(),
});

export type LgmCoveragesReportKpis = z.infer<
  typeof lgmCoveragesReportKpisSchema
>;

export const lgmCoveragesReportMonthlySchema =
  lgmCoveragesReportEndorsementSchema
    .pick({
      marketingMonth: true,
      commodity: true,
      totalTargetMarketings: true,
      totalEndorsements: true,
      totalProducerPremium: true,
    })
    .extend({
      totalAllocatedActualIndemnity: zDecimalNullable().optional(),
      totalAllocatedNetActualIndemnity: zDecimalRequired()
        .nullable()
        .optional(),
      totalAllocatedProjectedIndemnity: zDecimalRequired()
        .nullable()
        .optional(),
      totalAllocatedNetProjectedIndemnity: zDecimalRequired()
        .nullable()
        .optional(),
    });

export type LgmCoveragesReportMonthly = z.infer<
  typeof lgmCoveragesReportMonthlySchema
>;

export const getLgmCoveragesReportResponseSchema = z.object({
  kpis: lgmCoveragesReportKpisSchema,
  s3FileKey: z.string(),
});
export type GetLgmCoveragesReportResponse = z.infer<
  typeof getLgmCoveragesReportResponseSchema
>;
