import { useCallback, useRef, useState } from 'react'
import { Box, Button, FormControl, InputLabel, Tooltip } from '@mui/material'
import {
  CloudUpload as UploadIcon,
  RestoreRounded as ResetIcon,
} from '@mui/icons-material'

import classNames from 'classnames'
import { noop } from 'common'
import { uploadFile } from '../../http/media'
import { api, insureIQApiClient } from '../../utils'
import Loading from '../Loading'
import { useToast } from '../../contexts'

export function ImageInput({
  name,
  value: valueProp,
  label,
  accept = '.jpg, .png, .svg',
  onChange = noop,
  permission = 'private',
  className,
  defaultValue = '',
  imgProps = {},
}) {
  const [value, setValue] = useState(valueProp || defaultValue)
  const [isUploadingFile, setIsUploadingFile] = useState(false)
  const inputRef = useRef()
  const { warningToast } = useToast()

  const handleChange = useCallback(
    async evt => {
      const { target } = evt
      const { files } = target
      const [file] = files
      const fileExt = file?.name.split('.').pop()
      const isInvalidFile = !fileExt || !accept.includes(fileExt)
      if (isInvalidFile) {
        warningToast(`Invalid file type! Allowed extensions: ${accept}`)
        setValue(valueProp || defaultValue)
        return
      }
      if (file) {
        setIsUploadingFile(true)
        const response = await insureIQApiClient.upload(file, permission)
        if (response.errors?.length) {
          console.error('Error uploading file:', response.errors[0].message)
          warningToast(response.errors[0].message)
          setValue(valueProp || defaultValue)
          return
        }

        const [preview] = response.files
        const fileEndpoint =
          permission === 'private'
            ? preview.filename
            : `${preview.filename}/public`
        const mediaUrl = api.urlString(`/media/${fileEndpoint}`)

        setValue(mediaUrl)

        setIsUploadingFile(false)
        onChange?.({
          ...evt,
          target: {
            name,
            value: mediaUrl,
          },
        })
        // onChange will not trigger if we try to upload the same file setting input value
        // back to empty string ensures a change is fired even wwhen selecting the same image
        target.value = ''
      }
    },
    [setIsUploadingFile, name, onChange, setValue, permission, warningToast],
  )

  const handleClear = useCallback(() => {
    setValue(defaultValue)
    onChange?.({ target: { name, value: defaultValue } })
  }, [name, onChange, defaultValue, setValue])

  return (
    <FormControl variant="outlined" className={classNames('flex-1', className)}>
      <InputLabel shrink htmlFor={name}>
        {label}
      </InputLabel>
      <Box className="flex gap-1">
        <Box className="flex flex-1 justify-center items-center flex-basis-66">
          <input
            name={name}
            accept={accept}
            type="file"
            onChange={handleChange}
            ref={inputRef}
            className="none"
          />
          {isUploadingFile && <Loading className="absolute" />}

          <Box
            {...imgProps}
            component="img"
            src={value}
            className={classNames(isUploadingFile && 'disabled')}
          />
        </Box>
        <Box sx={{ minWidth: 110, maxWidth: 250 }} className="flex-basis-33">
          <Box className="flex flex-col gap-1 justify-between">
            <Button
              disabled={isUploadingFile}
              variant="outlined"
              color="primary"
              onClick={() => inputRef.current.click()}
            >
              <Tooltip title="Upload Image" arrow>
                <UploadIcon data-cy="UploadIcon" />
              </Tooltip>
            </Button>

            <Button
              variant="outlined"
              color="error"
              onClick={handleClear}
              disabled={!value || defaultValue === value}
            >
              <Tooltip title="Reset to Default" arrow>
                <ResetIcon data-cy="ResetIcon" />
              </Tooltip>
            </Button>
          </Box>
        </Box>
      </Box>
    </FormControl>
  )
}

export default ImageInput
