// define a type for Logger that is the union of winston.Logger and console logging functions
export type Logger = {
  silly: Console['debug'];
  debug: Console['debug'];
  info: Console['info'];
  warn: Console['warn'];
  error: Console['error'];
};

export type CreateLogger = (
  moduleOrFileName: string,
  additionalContext?: object
) => Logger;

const _defaultLogger: Logger = {
  silly: console.debug.bind(console),
  debug: console.debug.bind(console),
  info: console.info.bind(console),
  warn: console.warn.bind(console),
  error: console.error.bind(console),
};

const _defaultCreateLogger = (moduleOrFileName: string): Logger =>
  _defaultLogger;

let _currentLogger: Logger = _defaultLogger;
let _currentCreateLogger: CreateLogger = _defaultCreateLogger;

export function loggingInit({
  logger,
  createLogger,
}: {
  logger?: Logger;
  createLogger?: CreateLogger;
}): void {
  if (logger) {
    _currentLogger = logger;
  }
  if (createLogger) {
    _currentCreateLogger = createLogger;
  }
}

export function createLogger(
  moduleOrFileName: string,
  additionalContext?: object
): Logger {
  return _currentCreateLogger(moduleOrFileName, additionalContext);
}

export function getLogger(): Logger {
  return _currentLogger;
}

// backwards compatibility
export default getLogger;
