export const UserRoles = {
  ADMIN: 'admin',
  AGENT: 'agent',
  CUSTOMER: 'customer',
  PARTNER: 'partner',
  SYSTEM_AGENT: 'systemAgent',
} as const;

export type UserRole = (typeof UserRoles)[keyof typeof UserRoles];

export const UserRoleTitles = {
  [UserRoles.ADMIN]: 'Administrator',
  [UserRoles.AGENT]: 'Agent',
  [UserRoles.CUSTOMER]: 'Customer',
  [UserRoles.PARTNER]: 'Partner',
  [UserRoles.SYSTEM_AGENT]: 'SystemAgent',
} as const;

export type UserRoleTitle =
  (typeof UserRoleTitles)[keyof typeof UserRoleTitles];
