import { z } from 'zod';
import {
  dbLgmEndorsementSchema,
  dbLgmEndorsementUpdateSchema,
} from './db-lgm-endorsement';
import {
  dbLrpEndorsementSchema,
  dbLrpEndorsementUpdateSchema,
} from './db-lrp-endorsement';

export const updateLrpCoverageRequestSchema = dbLrpEndorsementUpdateSchema
  .pick({
    type: true,
    commodity: true,
    commodityType: true,
    commodityTypeCode: true,
    details: true,
    agentSignedAt: true,
    createdBy: true,
    customerSignedAt: true,
    externalStatus: true,
    isExternallyManaged: true,
    policyId: true,
    salesEffectiveDate: true,
    status: true,
    submittedToAipAt: true,
    watchedAt: true,
    deletedAt: true,
    headCount: true,
    targetWeightQuantityCwt: true,
    coveragePriceTotal: true,
    entityHistoryId: true,
    coverageLevel: true,
  })
  // API Endpoint specific fields
  .extend({
    sendAgentNotification: z.boolean().optional(),
    sendCustomerNotification: z.boolean().optional(),
  });
export type UpdateLrpCoverageRequest = z.infer<
  typeof updateLrpCoverageRequestSchema
>;

export const updateLgmCoverageRequestSchema = dbLgmEndorsementUpdateSchema
  .pick({
    type: true,
    commodity: true,
    commodityType: true,
    commodityTypeCode: true,
    details: true,
    agentSignedAt: true,
    createdBy: true,
    customerSignedAt: true,
    externalStatus: true,
    isExternallyManaged: true,
    policyId: true,
    salesEffectiveDate: true,
    status: true,
    submittedToAipAt: true,
    watchedAt: true,
    deletedAt: true,
    liveCattleTargetCwtPerHead: true,
    feederCattleTargetCwtPerHead: true,
    cornTargetBuPerHead: true,
    entityHistoryId: true,
  })
  // API Endpoint specific fields
  .extend({
    sendAgentNotification: z.boolean().optional(),
    sendCustomerNotification: z.boolean().optional(),
  });
export type UpdateLgmCoverageRequest = z.infer<
  typeof updateLgmCoverageRequestSchema
>;

export const updateCoverageRequestSchema = z.union([
  updateLrpCoverageRequestSchema,
  updateLgmCoverageRequestSchema,
]);
export type UpdateCoverageRequest = z.infer<typeof updateCoverageRequestSchema>;

// Responses
export const updateLrpCoverageResponseSchema = z.object({
  data: z
    .object({
      coverage: dbLrpEndorsementSchema,
      filename: z.string().nullable(),
    })
    .array(),
});
export type UpdateLrpCoverageResponse = z.infer<
  typeof updateLrpCoverageResponseSchema
>;

export const updateLgmCoverageResponseSchema = z.object({
  data: z
    .object({
      coverage: dbLgmEndorsementSchema,
      filename: z.string().nullable(),
    })
    .array(),
});
export type UpdateLgmCoverageResponse = z.infer<
  typeof updateLgmCoverageResponseSchema
>;
