import { api } from '../utils'

export const getUsersByRoleSlug = (
  id = 'customer',
  params: Record<string, any> = {},
) => api.get(`/roles/users`, { id, ...params }).then(res => res)

export const getRole = (id: string, params: Record<string, any> = {}) =>
  api.get(`/roles/${id}`, params).then(res => res)

export const getRoleUsers = ({
  id,
  ...params
}: {
  id: string
  params?: Record<string, any>
}) => api.get(`/roles/${id}/users`, params).then(res => res.data)
