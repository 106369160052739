import { z } from 'zod';
import { zDate, zPhoneNumber } from '@harvestiq/zod';
import {
  AipNames,
  ApplicationTypes,
  ProgramIndicatorDescriptions,
  InsureIQPolicyTypes,
} from '@harvestiq/constants';
import { livestockPolicyTypesSchema, livestockCapacitySchema } from './shared';
import { BusinessType } from '../companies/business-types';
import { entityHistorySchema } from '../entities-history/entity-history';

const additionalSignerSchema = z.object({
  signer: z.string(),
});

const livestockLocationSchema = z.object({
  state: z.string().optional(),
  county: z.string().optional(),
  zip: z.string().min(5).max(10).optional(), // allow optional add-on (e.g. 12345-6789)
});
export type LivestockLocation = z.infer<typeof livestockLocationSchema>;

const sharedSbiSharesSchema = z.object({
  address: z.string().optional(),
  addressNumber: z.string().optional(),
  applicationTypeOption: z.nativeEnum(ProgramIndicatorDescriptions), // aka programIndicator
  city: z.string().optional(),
  county: z.string().optional(),
  phone: zPhoneNumber().optional(), // RMA can send it as null/empty
  shares: z.coerce.number().gte(0).lte(100), // Sometimes a string, sometimes a number
  state: z.string().length(2),
  zip: z.string().min(5).max(10), // allow optional add-on (e.g. 12345-6789)
});

const individualSbiSharesSchema = sharedSbiSharesSchema.extend({
  ownerType: z.literal('Individual'),
  addressLine1: z.string().optional(),
  email: z.string().email().optional(),
  firstName: z.string(),
  lastName: z.string(),
  middleName: z.string().optional(),
  // NOTE: This comes from insureiq/common spouseType
  // TODO: Fix this to use a @harvestiq/constants reference
  spouseType: z.enum(['applicant', 'non-applicant']).optional(),
  ssn: z.string().optional(),
  suffix: z.string().optional(),
  // static literal business fields, discriminated union types require all fields to be present
  businessName: z.undefined(),
  businessType: z.undefined(),
  ein: z.undefined(),
});
export type SpouseType = z.infer<
  typeof individualSbiSharesSchema
>['spouseType'];

const businessSbiSharesSchema = sharedSbiSharesSchema.extend({
  ownerType: z.literal('Business'),
  businessName: z.string(),
  businessType: z.nativeEnum(BusinessType),
  ein: z.string(),
  // static literal individual fields, discriminated union types require all fields to be present
  addressLine1: z.undefined(),
  email: z.undefined(),
  firstName: z.undefined(),
  lastName: z.undefined(),
  middleName: z.undefined(),
  spouseType: z.undefined(),
  ssn: z.undefined(),
  suffix: z.undefined(),
});

const sbiSharesSchema = z.discriminatedUnion('ownerType', [
  individualSbiSharesSchema,
  businessSbiSharesSchema,
]);
export type SbiShares = z.infer<typeof sbiSharesSchema>;

const signatureSchema = z.object({
  fullName: z.string(),
  isAdditionalSigner: z.boolean(),
  signedAt: zDate().nullable(),
});

const spouseSchema = z.object({
  firstName: z.string(),
  middleName: z.string().optional(),
  lastName: z.string(),
  suffix: z.string().optional(),
  ssn: z.string().optional(),
  phone: zPhoneNumber().optional(),
  email: z.string().email().optional(),
  applicationTypeOption: z.nativeEnum(ProgramIndicatorDescriptions),
  address: z.string(),
  addressLine1: z.string().optional(),
  addressNumber: z.string().optional(),
  city: z.string(),
  county: z.string().optional(),
  state: z.string().length(2),
  zip: z.string().min(5).max(10), // allow optional add-on (e.g. 12345-6789)
});

const sharedPolicyDataSchema = z.object({
  additionalSigners: z.array(additionalSignerSchema).optional(),
  addressLine1: z.string().optional(),
  addressLine2: z.string().optional(),
  addressNumber: z.string().optional(),
  agentId: z.string().optional(),
  agentSignedAt: zDate().optional(),
  applicationOwnerType: z.enum(['Farmer', 'Landlord']).optional(),
  applicationTypeOption: z.nativeEnum(ProgramIndicatorDescriptions).optional(),
  city: z.string().optional(),
  cond1: z.boolean().optional(),
  cond2: z.boolean().optional(),
  cond3: z.boolean().optional(),
  cond4: z.boolean().optional(),
  cond5: z.boolean().optional(),
  cond6: z.boolean().optional(),
  contactEmail: z.string().email().optional(),
  contactPhone: zPhoneNumber().optional(),
  county: z.string().optional(),
  customerSignedAt: zDate().optional(),
  dob: z.string().optional(),
  email: z.string().email().optional(),
  hasAgency: z.boolean().optional(),
  insurerName: z.nativeEnum(AipNames).optional(),
  isAccepted: z.boolean().optional(),
  isElectronicallySigned: z.boolean().optional(),
  isEighteen: z.boolean().optional(),
  livestock: livestockPolicyTypesSchema.optional(),
  livestockCapacity: livestockCapacitySchema.optional(),
  livestockLocation: livestockLocationSchema.optional(),
  maritalStatus: z.boolean().optional(),
  middleName: z.string().nullable().optional(),
  name: z.string().optional(),
  previousPolicyId: z.string().optional(),
  previousPolicyProvider: z.string().optional(),
  recipientName: z.string().optional(),
  reinsuranceYear: z.number().optional(),
  shares: z.array(sbiSharesSchema),
  signatureFullName: z.string().optional(),
  signatures: z.array(signatureSchema),
  state: z.string().optional(),
  submittedToAipAt: zDate().optional(),
  timezone: z.string().optional(),
  type: z.nativeEnum(InsureIQPolicyTypes).optional(),
  zip: z.string().optional(),
  createdAt: z.string().optional(),

  // m13 ingestion fields
  aip_code: z.string().optional(),
  record_type_code: z.string().optional(),
  aip_policy_producer_key: z.string().optional(),
  aip_insurance_agent_key: z.string().nullable().optional(),
  aip_insurance_in_force_key: z.string().optional(),
  location_county_code: z.string().nullable().optional(),
  insurance_plan_code: z.string().nullable().optional(),
  commodity_code: z.string().nullable().optional(),
  commodity_year: z.string().nullable().optional(),
  type_code: z.string().nullable().optional(),
  practice_code: z.string().nullable().optional(),
  commodity_type_code: z.string().nullable().optional(),
  class_code: z.string().nullable().optional(),
  sub_class_code: z.string().nullable().optional(),
  intended_use_code: z.string().nullable().optional(),
  irrigation_practice_code: z.string().nullable().optional(),
  cropping_practice_code: z.string().nullable().optional(),
  organic_practice_code: z.string().nullable().optional(),
  interval_code: z.string().nullable().optional(),
  wa_number: z.string().nullable().optional(),
  coverage_type_code: z.string().nullable().optional(),
  late_reported_reason_code: z.string().nullable().optional(),
  dual_coverage_flag: z.string().nullable().optional(),
  fee_prepaid_flag: z.string().nullable().optional(),
  fsa_administrative_state_code: z.string().nullable().optional(),
  fsa_administrative_county_code: z.string().nullable().optional(),
  fsa_rma_entity_difference_code: z.string().nullable().optional(),
  cims_information_code: z.string().nullable().optional(),
  administrative_fee_waiver_code: z.string().nullable().optional(),
  cancellation_transfer_signed_flag: z.string().nullable().optional(),
  coverage_level_percent: z.string().nullable().optional(),
  price_election_percent: z.string().nullable().optional(),
  dispute_settlement_code: z.string().nullable().optional(),
  dispute_notification_date: z.string().nullable().optional(),
  insurance_option_code_list: z.string().nullable().optional(),
  price_indicator_code: z.string().nullable().optional(),
  added_county_indicator_code: z.string().nullable().optional(),
  added_county_aip_policy_producer_key: z.string().nullable().optional(),
  added_county_aip_insurance_in_force_key: z.string().nullable().optional(),
  policy_history_request_code: z.string().nullable().optional(),
  reference_commodity_year: z.string().nullable().optional(),
  percent_of_value: z.string().nullable().optional(),
  entity_certification_code: z.string().nullable().optional(),
  associated_aip_policy_producer_key: z.string().nullable().optional(),
  associated_aip_insurance_in_force_key: z.string().nullable().optional(),
  coverage_range: z.string().nullable().optional(),
  policy_cancellation_code: z.string().nullable().optional(),
  unit_structure_transaction_code: z.string().nullable().optional(),
  underlying_aip_policy_producer_key: z.string().nullable().optional(),
  underlying_aip_insurance_in_force_key: z.string().nullable().optional(),

  entity: entityHistorySchema.nullable().optional(),
});

export const individualPolicyDataSchema = sharedPolicyDataSchema.extend({
  applicationType: z.literal(ApplicationTypes.INDIVIDUAL),
  firstName: z.string(),
  lastName: z.string(),
  spouse: spouseSchema.or(z.object({})).optional(),
  ssn: z.string().optional(),
  suffix: z.string().optional(),
  // static literal business fields, discriminated union types require all fields to be present
  authorizedRep: z.undefined(),
  business: z.undefined(),
  businessType: z.undefined(),
  ein: z.undefined(),
  stateOfIncorporation: z.undefined(),
});
export type IndividualPolicyData = z.infer<typeof individualPolicyDataSchema>;

export const businessPolicyDataSchema = sharedPolicyDataSchema.extend({
  applicationType: z.literal(ApplicationTypes.BUSINESS),
  business: z.string(), // name
  businessType: z.nativeEnum(BusinessType),
  ein: z.string(),
  authorizedRep: z.string().nullable(),
  stateOfIncorporation: z.string().nullable(),
  // Static literal individual fields, discriminated union types require all fields to be present
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  spouse: z.object({}).optional(),
  ssn: z.undefined(),
  suffix: z.undefined().nullable(),
});
export type BusinessPolicyData = z.infer<typeof businessPolicyDataSchema>;

export const policyDataSchema = z.discriminatedUnion('applicationType', [
  individualPolicyDataSchema,
  businessPolicyDataSchema,
]);
export type PolicyData = z.infer<typeof policyDataSchema>;
