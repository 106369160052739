import { z } from 'zod';

export const uploadMediaRequestSchema = z.object({
  file: z.any(),
  permission: z.string().optional(),
});

export type UploadMediaRequest = z.infer<typeof uploadMediaRequestSchema>;

export const uploadMediaResponseSchema = z.object({
  files: z
    .object({
      statusCode: z.number(),
      filename: z.string(),
      metaData: z.object({
        fieldname: z.string(),
        originalname: z.string(),
        encoding: z.string(),
        mimetype: z.string(),
        size: z.number(),
      }),
      id: z.string(),
    })
    .array(),
});

export type UploadMediaResponse = z.infer<typeof uploadMediaResponseSchema>;
