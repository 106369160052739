import { z } from 'zod';
import { RmaCommodityCodes } from '@harvestiq/constants';
import {
  GeneratedAlways,
  Selectable,
  Insertable,
  Updateable,
  Generated,
} from 'kysely';

export interface PolicyCoveragesTable {
  id: GeneratedAlways<string>;
  policyId: string;
  fipsCountyCode: string | null; // Five digit FIPS county code, see RMA ADM A00440
  commodityCode: RmaCommodityCodes;
  createdAt: GeneratedAlways<Date>;
  updatedAt: Generated<Date>;
  deletedAt: Date | null;
}

export type DbPolicyCoverage = Selectable<PolicyCoveragesTable>;
export const dbPolicyCoverageSchema = z.object({
  id: z.string(),
  policyId: z.string(),
  fipsCountyCode: z.string().nullable(),
  commodityCode: z.nativeEnum(RmaCommodityCodes),
  createdAt: z.date(),
  updatedAt: z.date(),
  deletedAt: z.date().nullable(),
});

export type DbPolicyCoverageInsert = Insertable<PolicyCoveragesTable>;
export const dbPolicyCoverageInsertSchema = dbPolicyCoverageSchema.omit({
  id: true,
  createdAt: true,
});

export type DbPolicyCoverageUpdate = Updateable<PolicyCoveragesTable>;
export const dbPolicyCoverageUpdateSchema = dbPolicyCoverageSchema
  .omit({
    id: true,
    createdAt: true,
  })
  .partial();
