import { useEffect, useState } from 'react'
import { Box, Button, Collapse, Typography } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { usePrevious } from 'react-use'
import classNames from 'classnames'

import errorBannerImage from '../../assets/images/error-banner.jpg'
import { Banner } from '../Banner'

import styles from './DefaultFallbackComponent.module.scss'

type ErrorWithMessage = {
  message: string
}

function isErrorWithMessage(error: unknown): error is ErrorWithMessage {
  return (
    typeof error === 'object' &&
    error !== null &&
    'message' in error &&
    typeof (error as ErrorWithMessage).message === 'string'
  )
}

/*
 * This is a workaround for the following issue:
 * https://mitchgavan.com/code-splitting-react-safely/
 * TL;DR; when we deploy a new version of the app and the current user is on the app,
 * they will get a 404 error when clicking Lazy-imported modules.
 */
export function isFailedToFetchModuleError(
  error: unknown,
): error is ErrorWithMessage {
  if (!isErrorWithMessage(error)) {
    return false
  }
  return (
    error.message.includes('Failed to fetch dynamically imported module') ||
    error.message.includes('Importing a module script failed')
  )
}

const IS_RELOADING_KEY = 'insureiq-error-boundary-reloading'
const MAX_RETRIES = 2
function checkShouldRetryReloading(): boolean {
  // use localStorage to keep track of retries
  const retries = parseInt(localStorage.getItem(IS_RELOADING_KEY) ?? '0', 10)
  if (retries >= MAX_RETRIES) {
    localStorage.removeItem(IS_RELOADING_KEY)
    return false
  }
  localStorage.setItem(IS_RELOADING_KEY, (retries + 1).toString())
  return true
}

type DefaultFallbackComponentProps = {
  error?: unknown
  componentStack?: string | null
  resetError: () => void
}

export const DefaultFallbackComponent = ({
  error,
  componentStack,
  resetError,
}: DefaultFallbackComponentProps) => {
  const location = useLocation()
  const previousPathname = usePrevious(location.pathname)
  const [showDetails, setShowDetails] = useState(false)

  useEffect(() => {
    if (previousPathname && location.pathname !== previousPathname) {
      resetError()
    }
  }, [location, resetError, previousPathname])

  // If error is null or undefined, show a generic message
  if (!error) {
    return (
      <Box className="flex flex-1 flex-col">
        <Typography variant="h4">An unknown error occurred.</Typography>
        <Button onClick={resetError} variant="contained">
          Try again
        </Button>
      </Box>
    )
  }

  if (isFailedToFetchModuleError(error)) {
    if (checkShouldRetryReloading()) {
      console.warn('Failed to fetch module, reloading...')
      window.location.reload()
      return <div>Reloading...</div>
    }
  }

  const handleToggleDetails = () => {
    setShowDetails(!showDetails)
  }

  return (
    <Box className="flex flex-1 flex-col">
      <Banner bgImgSrc={errorBannerImage} />
      <Typography variant="h4">Something went wrong:</Typography>
      <pre className={classNames(styles.stackTrace, 'my1')}>
        {isErrorWithMessage(error)
          ? error.message
          : 'An unknown error occurred'}
      </pre>
      <Box className="flex gap-1 justify-center">
        <Button
          variant="contained"
          color="secondary"
          onClick={handleToggleDetails}
        >
          {showDetails ? 'Hide Details' : 'Show Details'}
        </Button>

        <Button onClick={resetError} variant="contained">
          Try again
        </Button>
      </Box>
      <Collapse in={showDetails}>
        <pre
          className={classNames(
            styles.stackTrace,
            'p2 color-red break-word bg-color-shade-10',
          )}
        >
          {componentStack ?? 'No component stack available.'}{' '}
          {/* Fallback if componentStack is null */}
        </pre>
      </Collapse>
    </Box>
  )
}
