import {
  useState,
  useEffect,
  useContext,
  createContext,
  PropsWithChildren,
} from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { pages } from 'common'
import { getLgmTargetWeightInitialValues } from '../../components/shared/lgmTargetWeight'

interface LGMContextValues {
  commodity: string
  commodityType: string
  deductible: number
  liveCattleTargetCwtPerHead: number | null
  feederCattleTargetCwtPerHead: number | null
  cornTargetBuPerHead: number | null
}

interface LGMContextType {
  initialLGMValues: LGMContextValues
  setLGMInitialValues: (values: LGMContextValues) => void
}

const defaultCattleTargetWeights =
  getLgmTargetWeightInitialValues('Calf Finishing')

const initialContext: LGMContextValues = {
  commodity: 'Cattle',
  commodityType: 'Calf Finishing',
  deductible: 0,
  liveCattleTargetCwtPerHead:
    defaultCattleTargetWeights.liveCattleTargetCwtPerHead,
  feederCattleTargetCwtPerHead:
    defaultCattleTargetWeights.feederCattleTargetCwtPerHead,
  cornTargetBuPerHead: defaultCattleTargetWeights.cornTargetBuPerHead,
}

const LGMContext = createContext<LGMContextType | undefined>(undefined)
const { Provider } = LGMContext

const useProvideLGM = (): LGMContextType => {
  const navigate = useNavigate()
  const location = useLocation()

  const [state, setState] = useState<LGMContextValues>(initialContext)

  useEffect(() => {
    if (location.pathname === pages.lgm.path) {
      navigate(pages.lgmQuote.path, { replace: true })
    }
  }, [navigate, location])

  return { initialLGMValues: state, setLGMInitialValues: setState }
}

export const useLGM = (): LGMContextType => {
  const context = useContext(LGMContext)
  if (!context) {
    throw new Error('useLGM must be used within an LGMProvider')
  }
  return context
}

const LGMProvider = ({ children }: PropsWithChildren) => {
  const { initialLGMValues, setLGMInitialValues } = useProvideLGM()
  return (
    <Provider value={{ initialLGMValues, setLGMInitialValues }}>
      {children}
    </Provider>
  )
}

export { LGMProvider }
