import { colorSchemeKeys, defaultColorScheme } from '@harvestiq/iiq/common'
import { isLiteralObject } from './object'
import {
  APP_LOGO,
  APP_LOGO_COMPACT,
  APP_NAME,
  CLIENT_PORT,
  IS_DEV,
  SERVER_PORT,
  harvestiqEmailAddress,
  harvestiqPhoneNumber,
} from './constants'

const getLinkSelector = mimeType => `link[rel='icon'][type='${mimeType}']`
export const updateFavicons = ({ favicon, compactLogo }, doc) => {
  if (favicon) {
    const icoLink = doc.querySelector(getLinkSelector('image/x-icon'))
    if (icoLink) {
      icoLink.href = favicon
    }
  }
  if (compactLogo) {
    const compactLogoLink = doc.querySelector(getLinkSelector('image/*'))
    const appleIconLink = doc.querySelector("link[rel='apple-touch-icon']")
    if (compactLogoLink) {
      compactLogoLink.href = compactLogo
    }
    if (appleIconLink) {
      appleIconLink.href = compactLogo
    }
  }
}

export const mergeOrgAppSettings = (
  settings = {},
  defaultSettings = orgAppSettingsDefaults,
) => {
  const defaultKeys = Object.keys(defaultSettings)
  const settingsKeys = Object.keys(settings)
  const mergedKeys = [...new Set([...defaultKeys, ...settingsKeys])]
  const mergedSettings = mergedKeys.reduce(
    (acc, key) => {
      const defaultVal = defaultSettings[key]
      let val = settings[key]

      if (val === undefined || (val === '' && colorSchemeKeys.includes(key))) {
        val = defaultVal
      }

      acc[key] =
        isLiteralObject(val) && defaultVal
          ? mergeOrgAppSettings(val, defaultVal)
          : val

      return acc
    },
    { ...defaultSettings },
  )

  if (typeof document !== 'undefined') {
    updateFavicons(mergedSettings, document)
  }

  return mergedSettings
}

export const orgAppSettingsDefaults = Object.freeze({
  id: null,
  name: APP_NAME,
  fullLogo: APP_LOGO,
  compactLogo: APP_LOGO_COMPACT,
  favicon: '/favicon.ico',
  loadingIndicator: 'ring',
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: '',
  zip: '',
  email: harvestiqEmailAddress,
  phone: harvestiqPhoneNumber,
  colorScheme: defaultColorScheme,
  domain: '',
  sendgridDomainId: null,
  sendgridDomainDns: null,
  isAdmin: false,
  gaTrackingId: '',
  hubspotAccountId: '',
  fmhApiKey: null,
  fmhSigningKey: null,
})

export const makeOrgAppSettingsId = orgAppSettingsId =>
  orgAppSettingsId.toLowerCase().replace(/[^\w]|_/g, '-')

export const makeOrgUrl = domain => {
  if (IS_DEV) {
    return `http://${domain}:${CLIENT_PORT}`
  }
  return `https://${domain}`
}

export const makeOrgUri = domain => {
  if (IS_DEV) {
    return `http://${domain}:${SERVER_PORT}`
  }
  return `https://${domain}`
}

export const makeOrgDeploymentName = orgAppSettingsId =>
  `portal-tenant-${orgAppSettingsId}`
