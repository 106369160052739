import React from 'react'
import ReactDOM from 'react-dom/client'
import { datadogRum } from '@datadog/browser-rum'
import { IS_DEV } from 'common'
import { getVersion, getEnv } from './config'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import './styles/globals.scss'
import App from './App'
import { AppProvider } from './contexts'

const version = getVersion()
const env = getEnv()
console.log(`Running portal client version: ${version} in '${env}' environment`)

if (['staging', 'test', 'production'].includes(env)) {
  datadogRum.init({
    applicationId: 'ba334cbf-f695-4164-b680-dda966614cd4',
    clientToken: 'pubc3168def0a909c5207d1faad1874b2d7',
    site: 'datadoghq.com',
    service: 'portal_client',
    env,
    version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 80,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  })
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <AppProvider>
      <App />
    </AppProvider>
  </React.StrictMode>,
)

if (!IS_DEV) {
  console.log('registering service worker')
  serviceWorkerRegistration.register()
} else {
  console.log('unregistering service worker')
  serviceWorkerRegistration.unregister()
}
