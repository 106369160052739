import { z } from 'zod';
import {
  commonFmhEndorsementSchema,
  fmhDocuSignSchema,
} from './endorsement-common';
import {
  RmaLgmCommodityCodes,
  RmaLgmCommodityTypeCodes,
} from '@harvestiq/constants';

export const fmhTargetMarketingSchema = z.object({
  targetMarketingValues: z.array(z.number().int().nullable()),
  cornEquivalentValues: z.array(z.number().nullable()), // For Dairy Only
  soybeanMealEquivalentValues: z.array(z.number().nullable()), // For Dairy Only
});
export type FmhTargetMarketing = z.infer<typeof fmhTargetMarketingSchema>;

export const fmhLgmEndorsementSchema = commonFmhEndorsementSchema.extend({
  commodityCode: z.nativeEnum(RmaLgmCommodityCodes),
  liveCattleTargetCwtPerHead: z.number().nullable(),
  feederCattleTargetCwtPerHead: z.number().nullable(),
  cornTargetBuPerHead: z.number().nullable(),
  // FMH wants commodity type code as string
  commodityTypeCode: z.string().refine((val) => {
    return Object.values(RmaLgmCommodityTypeCodes).includes(parseInt(val, 10));
  }),
  deductible: z.number(),
  targetMarketings: fmhTargetMarketingSchema,

  endDate: z.null(),
});
export type FmhLgmEndorsement = z.infer<typeof fmhLgmEndorsementSchema>;

export const createFmhLgmEndorsementSchema = fmhLgmEndorsementSchema
  .pick({
    // Required null fields
    aipPremiumKey: true,
    aipInsuranceInForceKey: true,
    fmhProducerPartyKey: true,
    fmhAgentPartyKey: true,
    policyNumber: true,
    insuredSignatureDate: true,
    agentSignatureDate: true,
    endDate: true,
    premium: true,
    liability: true,
    status: true,
    // Required fields
    aipPolicyProducerKey: true,
    aipInsuranceAgentKey: true,
    reinsuranceYear: true,
    commodityCode: true,
    countyCode: true,
    commodityTypeCode: true,
    salesEffectiveDate: true,
    insuredSharePercent: true,
    deductible: true,
    targetMarketings: true,
  })
  // FMH Requires that all optional fields be null instead of undefined
  .extend({
    aipPremiumKey: z.literal(null),
    aipInsuranceInForceKey: z.literal(null),
    fmhProducerPartyKey: z.literal(null),
    fmhAgentPartyKey: z.literal(null),
    policyNumber: z.literal(null),
    insuredSignatureDate: z.literal(null),
    agentSignatureDate: z.literal(null),
    endDate: z.literal(null),
    premium: z.literal(null),
    liability: z.literal(null),
    status: z.literal(null),
    endorsementLength: z.literal(null),
    endorsementLengthCodeId: z.literal(null),
    headCount: z.literal(null),
    targetWeightQuantity: z.literal(null),
    coveragePrice: z.literal(null),
    // DocuSign is only included for create requests
    docuSign: fmhDocuSignSchema,
  });
export type CreateFmhLgmEndorsement = z.infer<
  typeof createFmhLgmEndorsementSchema
>;
