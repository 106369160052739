export const enum RevIQRoleType {
  Admin = 0,
  Editor = 1,
  Viewer = 2,
  Advisor = 3,
}

export function reviqRoleTypeToString(roleType: RevIQRoleType) {
  switch (roleType) {
    case RevIQRoleType.Admin:
      return 'Admin';
    case RevIQRoleType.Editor:
      return 'Editor';
    case RevIQRoleType.Viewer:
      return 'Viewer';
    case RevIQRoleType.Advisor:
      return 'Advisor';
    default:
      return 'Unknown';
  }
}

export const enum IIQRoles {
  ADMIN = 'admin',
  SUPER_ADMIN = 'superAdmin',
  AGENT = 'agent',
  CUSTOMER = 'customer',
  PARTNER = 'partner',
}
