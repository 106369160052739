import { useCallback, useMemo, useState } from 'react'
import { useAppSettings } from '../contexts'
import { localStorage } from '../utils'

export const useLocalStorage = <T>(
  baseLocalStorageKey: string,
  initialValue?: T,
) => {
  const { sha } = useAppSettings()

  const localStorageKey = useMemo(
    () => `${baseLocalStorageKey}-${sha}`,
    [baseLocalStorageKey, sha],
  )

  const [state, setState] = useState<T | undefined>(
    localStorage.get(localStorageKey) ?? initialValue,
  )

  const setValue = useCallback(
    (valueOrPrevValFunction: T | ((prevValue: T | undefined) => T)) => {
      const newState =
        typeof valueOrPrevValFunction === 'function'
          ? (valueOrPrevValFunction as (prevValue: T | undefined) => T)(state)
          : valueOrPrevValFunction

      localStorage.set(localStorageKey, newState)
      setState(newState)
    },
    [localStorageKey, setState, state],
  )

  const removeValue = useCallback(() => {
    localStorage.remove(localStorageKey)
    setState(undefined)
  }, [localStorageKey, setState])

  // TODO: refactor to typical react state hook return signature
  // eg: [value, setValue]
  return {
    value: state,
    setValue,
    removeValue,
  }
}
