import { LegacySequelizeUser } from '@harvestiq/iiq/common'
import { api } from '../utils'

// TODO: All these methods should have an equivalent on insureiq-api-client and these below should be removed

export const getUserPolicies = (
  userId = 'me',
  params: Record<string, any> = {},
) => api.get(`/users/${userId}/policies`, params).then(resp => resp?.data)

export const updateUserProfileByUserId = (
  userId: string,
  body: Record<string, any>,
) => api.patch(`/users/${userId}/profile`, body)

export const updateUserPersonalInformations = (
  userId: string,
  params: Record<string, any>,
) => api.patch(`/profile/personal/${userId}`, params)

export const updateUserBusinessInformation = (
  userId: string,
  params: Record<string, any>,
) => api.patch(`/profile/business/${userId}`, params)

export const addUser = (query: Record<string, any> = {}): Promise<any> =>
  api.post('/users', query).then(resp => resp)

export const updateUser = (
  userId: string,
  params: Record<string, any>,
): Promise<{ data: { user: LegacySequelizeUser } }> =>
  api.patch(`/users/${userId}`, params)

export const blockUsers = (query: Record<string, any>) =>
  api.delete(`/users`, query).then(({ data, errors }) => ({ data, errors }))

export const deleteUserById = (userId: string) =>
  api.delete(`/users/${userId}`).then(({ data, errors }) => ({ data, errors }))

export const updateUsers = (
  query: Record<string, any>,
  updates: Record<string, any> = {},
): Promise<any> =>
  api
    .patch(`/users`, { where: query, ...updates })
    .then(({ data, errors }) => ({ data, errors }))

export const removeUserRole = (userId: string, roleId: string) =>
  api
    .delete(`/users/${userId}/roles/${roleId}`)
    .then(({ data, errors }) => ({ data, errors }))

export const addUserRole = (userId: string, roleId: string) =>
  api
    .post(`/users/${userId}/roles`, { roleId })
    .then(({ data, errors }) => ({ data, errors }))

export const assignAgent = (userId: string, agentId: string) =>
  api.patch(`/users/${userId}/agents`, { agentId })
