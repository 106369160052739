import { zPhoneNumber } from '@harvestiq/zod';
import { z } from 'zod';
import { legacySequelizeUserSchema } from './shared';
import { userSchema } from './user';
import { dbUserSchema } from './db-user';

const baseRequest = z.object({
  firstName: z.string().trim(),
  lastName: z.string().trim(),
  phone: zPhoneNumber(),
  email: z.string().trim().toLowerCase().email(),
  password: z.string().optional(),
  passwordVerify: z.string().optional(),
  confirmedAt: z.string().datetime().optional(),
  roles: z.array(z.string().trim()).min(1),
});

export const createUserRequestSchema = baseRequest.superRefine((val, ctx) => {
  if (
    (!!val.password || !!val.passwordVerify) &&
    val.password !== val.passwordVerify
  ) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Passwords must match',
      path: ['passwordVerify'],
    });
  }
});
export type CreateUserRequest = z.infer<typeof createUserRequestSchema>;

export const createUserResponseSchema = z.object({
  data: z.object({
    user: legacySequelizeUserSchema,
  }),
});
export type CreateUserResponse = z.infer<typeof createUserResponseSchema>;

// Note: the following schema is used in the signup endpoint
export const createUserWithRedirectRequestSchema = baseRequest
  .extend({
    redirectTo: z.string().trim(),
    roles: z.array(z.string().trim()).min(1).optional(),
    password: z.string(),
    passwordVerify: z.string(),
  })
  .superRefine((val, ctx) => {
    if (val.password !== val.passwordVerify) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Passwords must match',
        path: ['passwordVerify'],
      });
    }
  });
export type CreateUserWithRedirectRequest = z.infer<
  typeof createUserWithRedirectRequestSchema
>;

export const createUserWithRedirectResponseSchema = z.object({
  user: dbUserSchema,
});
export type CreateUserWithRedirectResponse = z.infer<
  typeof createUserWithRedirectResponseSchema
>;
