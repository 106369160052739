import { z } from 'zod';

export const apiKeySchema = z.object({
  id: z.string().uuid(),
  userId: z.string().uuid(),
  label: z.string(),
  clientId: z.string(),
  clientSecret: z.string().optional(),
  enabled: z.boolean(),
});

// multiple api keys
export const getApiKeysRequestSchema = z.object({
  userId: z.string().uuid().optional(),
});
export type GetApiKeysRequest = z.infer<typeof getApiKeysRequestSchema>;

export const getApiKeysResponseSchema = z.object({
  data: z.array(apiKeySchema),
});

export const getApiKeysErrorResponseSchema = z.object({
  data: z.tuple([]),
  errors: z.array(z.object({ message: z.string() })),
});

// single api key
export const getApiKeyRequestSchema = z.object({
  apiKeyId: z.string().uuid(),
});
export type GetApiKeyRequest = z.infer<typeof getApiKeyRequestSchema>;

export const getApiKeyResponseSchema = z.object({
  data: z.array(apiKeySchema),
});

export const getApiKeyErrorResponseSchema = z.object({
  data: z.tuple([]),
  errors: z.array(z.object({ message: z.string() })),
});
