import { useCallback, useRef, useState } from 'react'
import { Button } from '@mui/material'
import { noop } from 'common'
import Avatar from '../Avatar'
import { useToast, useUser } from '../../contexts'
import { LoadingIndicator } from '../LoadingIndicator'
import { insureIQApiClient } from '../../utils'

const accept = '.jpg, .png'

export function AvatarInput({ name, value: valueProp, onChange = noop }) {
  const { user } = useUser()
  const [value, setValue] = useState(valueProp)
  const [isUploadingFile, setIsUploadingFile] = useState(false)
  const inputRef = useRef()
  const { warningToast } = useToast()

  const setFileValue = useCallback(file => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.addEventListener('load', () => setValue(reader.result), false)
  }, [])

  const handleChange = useCallback(
    async evt => {
      const { target } = evt
      const { files } = target
      const [file] = files
      const fileExt = file?.name.split('.').pop()
      const isInvalidFile = !fileExt || !accept.includes(fileExt)
      if (isInvalidFile) {
        warningToast(`Invalid file type! Allowed extensions: ${accept}`)
        setValue(valueProp)
        return
      }
      setFileValue(file)
      if (files) {
        setIsUploadingFile(true)
        const response = await insureIQApiClient.upload(file)

        setIsUploadingFile(false)

        if (response.errors?.length) {
          console.error('Error uploading file:', response.errors[0].message)
          warningToast(response.errors[0].message)
          setValue(valueProp)
          return
        }
        const [avatar] = response.files
        onChange?.({
          ...evt,
          target: { name, value: avatar.id },
        })
      }
    },
    [
      name,
      valueProp,
      setIsUploadingFile,
      onChange,
      setFileValue,
      setValue,
      warningToast,
    ],
  )

  return (
    <div className="flex flex-1 justify-start items-center gap-2">
      <input
        name={name}
        accept={accept}
        style={{ display: 'none' }}
        type="file"
        onChange={handleChange}
        ref={inputRef}
      />
      <Avatar
        className="avatar-square"
        fullName={user?.fullName}
        avatarUrl={value}
      />
      <Button
        variant="outlined"
        color="primary"
        onClick={() => inputRef.current.click()}
        endIcon={isUploadingFile ? <LoadingIndicator size={24} /> : null}
      >
        {isUploadingFile ? 'Uploading Photo' : 'Add Photo'}
      </Button>
    </div>
  )
}

export default AvatarInput
