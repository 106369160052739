import { useQuery } from '@tanstack/react-query'
import { useRedirect, useUser } from '../contexts'
import { insureIQApiClient } from '../utils'
import { pages } from 'common'

const removeSearchParams = (...paramNames: string[]) => {
  const urlObject = new URL(window.location.href)
  paramNames.forEach(paramName => urlObject.searchParams.delete(paramName))
  window.history.replaceState({}, document.title, urlObject.toString())
}

const getSearchParams = (...paramNames: string[]) => {
  const searchParams = new URLSearchParams(window.location.search)
  return paramNames.reduce((acc, paramName) => {
    acc[paramName] = searchParams.get(paramName)
    return acc
  }, {} as Record<string, string | null>)
}

export const useVerifyToken = () => {
  const { user, setUser } = useUser()
  const { redirectOrNavigate } = useRedirect()
  const { code, redirect: redirectFromQueryParams } = getSearchParams(
    'code',
    'redirect',
  )

  useQuery(
    ['verifyToken'],
    async () => {
      removeSearchParams('code')
      const response = await insureIQApiClient.confirmAccount(code!)

      if (response) {
        setUser(response.user)

        if (redirectFromQueryParams) {
          redirectOrNavigate(redirectFromQueryParams, {
            replace: true,
          })
        }
      }

      return response
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!code,
      retry: false,
      staleTime: Infinity,
      refetchOnReconnect: false,
    },
  )

  return {
    code,
    user,
  }
}
