import { harvestiqEmailAddress } from 'common'

type EmailLinkProps = {
  email?: string | null
}

const EmailLink = ({ email = harvestiqEmailAddress }: EmailLinkProps) => {
  return (
    <a href={`mailto:${email}`} target="_blank" rel="noreferrer">
      {email}
    </a>
  )
}

export default EmailLink
