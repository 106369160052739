import {
  useState,
  createContext,
  useContext,
  useCallback,
  useEffect,
  useMemo,
  ReactNode,
} from 'react'
import { useNavigate } from 'react-router-dom'
import { LegacySequelizeUser, LoginResponse } from '@harvestiq/iiq/common'

import { pages, toPascalCase, objsBy } from 'common'
import { insureIQApiClient, localStorage } from '../../utils'
import Loading from '../../components/Loading/Loading'
import { useRedirect } from '../Redirect'
import { useReferrerCookie } from '../../hooks/useReferrerCookie'
import { useAppSettings } from '../AppSettingsProvider'
import { Role } from '@harvestiq/constants'

type IsRole = {
  isAdmin: boolean
  isAgent: boolean
  isSuperAdmin: boolean
  isCustomer: boolean
  isPartner: boolean
}

type UserDefaults = {
  roles: string[]
} & IsRole

type UserState = Omit<LegacySequelizeUser, 'roles'> & UserDefaults

const userDefaults = (): UserDefaults => ({
  roles: [],
  isAdmin: false,
  isAgent: false,
  isSuperAdmin: false,
  isCustomer: false,
  isPartner: false,
})

// TODO: This method could be removed if we can get the pages to use the roles object array instead of a transformed roles string array
export const parseUserData = (
  userData: LegacySequelizeUser,
  allRoles: string[],
): UserState => {
  const { roles: userRoles = [], events: userEvents = [] } = userData

  const parsedRoles = userRoles.reduce((acc, { id }) => {
    acc.roles.push(id)
    acc[`is${toPascalCase(id)}` as keyof IsRole] = true
    return acc
  }, userDefaults())

  // If the user is an admin, give them all roles
  if (parsedRoles.isAdmin && allRoles?.length) {
    allRoles.forEach(roleId => {
      parsedRoles[`is${toPascalCase(roleId)}` as keyof IsRole] = true
    })
  }

  return { ...userData, ...parsedRoles, events: objsBy(userEvents, 'name') }
}

const isUserProfileComplete = (user: UserState | null) => {
  return !!(user?.firstName && user?.lastName && user?.profile?.primaryPhone)
}

export type UserProviderType = {
  isLoaded: boolean
  user: UserState | null
  isUserProfileComplete: boolean
  setUser: (userData: LegacySequelizeUser | null) => void
  signIn: (data: {
    email: string
    password: string
    redirectTo?: string
  }) => Promise<LoginResponse | null>
  signOut: () => Promise<void>
  getMe: () => Promise<LegacySequelizeUser | null>
  hasRole: (roleId: Role | 'superAdmin') => boolean
  hasRoles: (roles: readonly Role[]) => boolean
}

export const UserContext = createContext<UserProviderType>(
  {} as UserProviderType,
)

const useProvideUser = () => {
  const navigate = useNavigate()
  const { deleteReferrerCookie } = useReferrerCookie()
  const { redirectOrNavigate } = useRedirect()
  const [user, setUserState] = useState<UserState | null>(null)
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const { roles: allRoles = [], refetch: refetchAppSettings } = useAppSettings()

  const setUser = useCallback(
    (userData: LegacySequelizeUser | null) => {
      if (!userData) {
        setUserState(null)
        return
      }
      const parsedUser = parseUserData(userData, allRoles)
      setUserState(parsedUser)

      // Referral cookie is only needed for unauthenticated users
      deleteReferrerCookie()
    },
    [deleteReferrerCookie, allRoles],
  )

  const getMe = useCallback(async () => {
    const userResp = await insureIQApiClient.getUserById({
      userId: user?.id ?? 'me',
    })
    if (userResp) {
      setUser(userResp)
      return userResp
    }
    return null
  }, [setUser])

  const checkForPreviousSession = () => {
    if (!user && !isLoaded) {
      getMe()
        .then(retrievedUser => {
          if (retrievedUser) {
            return redirectOrNavigate()
          }
          return retrievedUser
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          setIsLoaded(true)
        })
    }
  }

  useEffect(checkForPreviousSession, [
    getMe,
    user,
    setIsLoaded,
    isLoaded,
    redirectOrNavigate,
  ])

  const defaultRedirectTo = '/'
  const signIn = async ({
    email,
    password,
    redirectTo = defaultRedirectTo,
  }: {
    email: string
    password: string
    redirectTo?: string
  }) => {
    const loggedInUser = await insureIQApiClient.login({
      email,
      password,
      redirectTo,
    })
    setUser(loggedInUser)
    await refetchAppSettings()
    const isAgent =
      loggedInUser?.roles?.some(role => role.id === 'agent') ?? false
    const redirectUrl =
      isAgent && redirectTo === defaultRedirectTo
        ? pages.agentDashboard.path
        : redirectTo
    redirectOrNavigate(redirectUrl, { replace: true })
    return loggedInUser
  }

  const hasRole = useCallback(
    (roleId: Role | 'superAdmin') => {
      if (!user) {
        return false
      }

      const isSuperAdmin = user.roles.some(role => role === 'superAdmin')

      if (isSuperAdmin) {
        return true // super admin must have access to everything
      }

      if (roleId !== 'superAdmin' && user.isAdmin) {
        return true // if user is an admin, they have access to everything except by super admin places
      }

      return user.roles.some(role => role === roleId)
    },
    [user],
  )

  const hasRoles = useCallback(
    (roles: readonly Role[] = []) => {
      if (!user) {
        return false
      }

      // empty roles requirement means user just needs to be logged in
      if (!roles.length) {
        return true
      }

      return roles.some(hasRole)
    },
    [hasRole, user],
  )

  const isUserProfileCompleteVal = useMemo(
    () => isUserProfileComplete(user),
    [user],
  )

  const signOut = async () => {
    await insureIQApiClient.signOut()
    setUser(null)
    navigate('/', { replace: true })
    localStorage.clear()
  }

  return {
    isLoaded,
    user,
    isUserProfileComplete: isUserProfileCompleteVal,
    setUser,
    signIn,
    signOut,
    getMe,
    hasRole,
    hasRoles,
  }
}

export const UserProvider = ({ children }: { children: ReactNode }) => {
  const userContext = useProvideUser()
  return (
    <UserContext.Provider value={userContext}>
      {/* Ensure that children have access to user before rendering them */}
      {userContext.isLoaded ? children : <Loading />}
    </UserContext.Provider>
  )
}

export const useUser = () => useContext(UserContext)
