import {
  forwardRef,
  useMemo,
  ReactNode,
  ComponentType,
  ComponentProps,
  ChangeEvent,
  Ref,
} from 'react'
import { Button as MuiButton, Tooltip, TooltipProps } from '@mui/material'
import { toTitleCase } from 'common'
import {
  AddressGroup,
  AvatarInput,
  Checkbox,
  CheckboxGroup,
  ColorPaletteEditor,
  DatePickerInput,
  DateRangePicker,
  DateTimePickerInput,
  EINMaskInput,
  Email,
  ImageInput,
  InputSelect,
  MaskInput,
  MultipleSelectCheckmarks,
  NumberGroup,
  NumberInput,
  Password,
  Percent,
  PhoneMaskInput,
  PlacesAutocomplete,
  Radio,
  Select,
  SelectAgents,
  SliderInput,
  Switch,
  TableSelect,
  TagsInput,
  Textarea,
  TextInput,
  ToggleButton,
  ZipMaskInput,
} from '../inputs'
import ColorPicker from '../inputs/ColorPicker'

const readonlyPointerEventsClass = 'events-none'

type ButtonProps = Omit<
  ComponentProps<typeof MuiButton>,
  'error' | 'helperText'
> & {
  error?: boolean
  helperText?: ReactNode
}

const Button = ({
  error: _error,
  helperText: _helperText,
  ...props
}: ButtonProps) => <MuiButton {...props} />

const noHelperTextFields = ['places']

type FieldTypeMap = Record<string, ComponentType<any>>

// this static map needs to be in the component
const fieldTypeMap: FieldTypeMap = {
  address: AddressGroup,
  avatar: AvatarInput,
  button: Button,
  checkbox: Checkbox,
  checkboxGroup: CheckboxGroup,
  color: ColorPicker,
  colorPalette: ColorPaletteEditor,
  date: DatePickerInput,
  daterange: DateRangePicker,
  dateTime: DateTimePickerInput,
  ein: EINMaskInput,
  email: Email,
  image: ImageInput,
  inputSelect: InputSelect,
  multiSelectCheckMarks: MultipleSelectCheckmarks,
  number: NumberInput,
  numberGroup: NumberGroup,
  password: Password,
  percent: Percent,
  phone: PhoneMaskInput,
  places: PlacesAutocomplete,
  radio: Radio,
  select: Select,
  selectAgents: SelectAgents,
  slider: SliderInput,
  ssn: MaskInput,
  switch: Switch,
  tableSelect: TableSelect,
  tagsInput: TagsInput,
  text: TextInput,
  textarea: Textarea,
  toggleButton: ToggleButton,
  zip: ZipMaskInput,
}

type InputFieldResolverProps = {
  type: string
  name?: string
  label?: string
  value?: any
  error?: boolean
  helperText?: ReactNode
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  [key: string]: any
}

function InputFieldResolver(
  {
    type,
    name: nameProp = '',
    label: labelProp,
    value,
    error,
    helperText,
    onChange,
    ...fieldProps
  }: InputFieldResolverProps,
  ref: Ref<any>,
) {
  const FieldComponent = fieldTypeMap[type] || fieldTypeMap.text
  const name = nameProp.split('.').pop() || ''
  const label = labelProp === undefined ? toTitleCase(name) : labelProp
  const isReadOnly = !!fieldProps.InputProps?.readOnly

  if (isReadOnly) {
    fieldProps.InputLabelProps = {
      ...fieldProps.InputLabelProps,
      classes: { root: readonlyPointerEventsClass },
    }
    fieldProps.classes = {
      ...fieldProps.classes,
      root: fieldProps?.classes?.root || `${readonlyPointerEventsClass}`,
    }
  }

  const helperTextNode = useMemo(() => {
    const helperTextVal = error || helperText
    return helperTextVal ? <>{helperTextVal}</> : null
  }, [error, helperText])

  return (
    FieldComponent && (
      <FieldComponent
        label={label}
        name={name}
        value={value ?? ''}
        error={!!error}
        {...(noHelperTextFields.includes(type)
          ? null
          : { helperText: helperTextNode })}
        onChange={onChange}
        {...fieldProps}
        ref={ref}
      />
    )
  )
}

const Field = forwardRef(InputFieldResolver)

type InputFieldProps = {
  tooltipProps?: TooltipProps & { icon?: ReactNode }
  RenderComponent?: ComponentType<{ children: ReactNode }>
  [key: string]: any
}

function InputField({
  tooltipProps,
  RenderComponent,
  ...props
}: InputFieldProps) {
  const icon = tooltipProps?.icon ? { tooltipIcon: tooltipProps.icon } : {}
  const fieldRender = RenderComponent ? (
    <RenderComponent {...props}>
      <Field {...icon} {...props} />
    </RenderComponent>
  ) : (
    <Field {...icon} {...props} />
  )

  if (tooltipProps) {
    return (
      <Tooltip {...tooltipProps}>
        {/* Wrapping field component into span to force tooltip to have effects */}
        {/* disabled slider was not activating tooltip */}
        <span className="flex flex-1">{fieldRender}</span>
      </Tooltip>
    )
  }

  return fieldRender
}

export default InputField
