import { zDecimalNullable, zDecimalRequired } from '@harvestiq/zod';
import { z } from 'zod';

export const lgmIndemnityDetailsByMarketingMonthsSchema = z.object({
  grossMarginGuarantee: zDecimalRequired(),
  marketingMonth: z.string(),
  numberofHead: z.number(),
  premium: zDecimalRequired(),
  subsidy: zDecimalRequired(),
  projectedGrossMargin: zDecimalNullable().optional(),
  projectedGrossMarginChange: zDecimalNullable().optional(),
  actualGrossMargin: zDecimalNullable().optional(),
  actualGrossMarginChange: zDecimalNullable().optional(),
  allocatedActualIndemnity: zDecimalNullable().optional(),
  allocatedNetActualIndemnity: zDecimalNullable().optional(),
  allocatedProjectedIndemnity: zDecimalNullable().optional(),
  allocatedNetProjectedIndemnity: zDecimalNullable().optional(),
});
export type LgmIndemnityDetailsByMarketingMonths = z.infer<
  typeof lgmIndemnityDetailsByMarketingMonthsSchema
>;

export const lgmIndemnityDetailsSchema = z.object({
  marketingMonths: z.array(lgmIndemnityDetailsByMarketingMonthsSchema),
});
export type LgmIndemnityDetails = z.infer<typeof lgmIndemnityDetailsSchema>;
