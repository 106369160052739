import { z } from 'zod';
import { dbAgentSchema } from './db-agent';

const dbAgentSchemaWithFullname = dbAgentSchema.extend({
  fullName: z.string().optional(),
});

export const getSigningAgentsResponseSchema = z.object({
  data: dbAgentSchemaWithFullname.array(),
});

export type GetSigningAgentsResponse = z.infer<
  typeof getSigningAgentsResponseSchema
>;
