import { z } from 'zod';
import { legacySequelizeUserSchema } from './shared';

export const loginRequestSchema = z.object({
  email: z.string(),
  password: z.string(),
  redirectTo: z.string(),
});
export type LoginRequest = z.infer<typeof loginRequestSchema>;

export const loginResponseSchema = legacySequelizeUserSchema;
export type LoginResponse = z.infer<typeof loginResponseSchema>;
