import { z } from 'zod';

export const colorSchemeSchema = z.object({
  primary: z.string(),
  secondary: z.string(),
  success: z.string(),
  info: z.string(),
  warning: z.string(),
  error: z.string(),
});

export type ColorScheme = z.infer<typeof colorSchemeSchema>;

export const defaultColorScheme: ColorScheme = {
  primary: '#AC141C',
  secondary: '#EF767A',
  success: '#49DCB1',
  info: '#445699',
  warning: '#EEB868',
  error: '#AC141C',
};

export const colorSchemeKeys = Object.keys(
  defaultColorScheme
) as (keyof ColorScheme)[];
