import { z } from 'zod';
import { zCalendarDate, zDecimalNullable } from '@harvestiq/zod';
import { RmaLrpCommodityTypeCodes } from '@harvestiq/constants';

// request
export const getLrpIndemnityRequestSchema = z.object({
  coverages: z.array(
    z.object({
      referenceId: z.string().optional(),
      salesEffectiveDate: zCalendarDate(),
      commodityTypeCode: z.coerce.number().refine((v) => {
        return z.nativeEnum(RmaLrpCommodityTypeCodes).parse(v); // workaround for qs
      }),
      // below are numbers as it is used in a GET request
      coverageLevel: z.coerce.number(),
      endorsementLength: z.coerce.number(),
      headCount: z.coerce.number(),
      targetWeightQuantityCwt: z.coerce.number(),
    })
  ),
});

export type GetLrpIndemnityRequest = z.infer<
  typeof getLrpIndemnityRequestSchema
>;

// response
export const getLrpIndemnityResponseSchema = z.object({
  data: z.array(
    z.object({
      referenceId: z.string().optional(),

      // premium fields

      livestockRate: zDecimalNullable(),
      expectedEndingValueTotal: zDecimalNullable(),
      expectedEndingValuePerHead: zDecimalNullable(),
      expectedEndingValuePerCwt: zDecimalNullable(),

      liabilityAmountTotal: zDecimalNullable(),
      liabilityAmountPerHead: zDecimalNullable(),
      liabilityAmountPerCwt: zDecimalNullable(),

      totalPremium: zDecimalNullable(),
      totalPremiumPerHead: zDecimalNullable(),
      totalPremiumPerCwt: zDecimalNullable(),

      subsidyRate: zDecimalNullable(),
      subsidyTotal: zDecimalNullable(),
      subsidyPerHead: zDecimalNullable(),
      subsidyPerCwt: zDecimalNullable(),

      producerPremiumTotal: zDecimalNullable(),
      producerPremiumPerHead: zDecimalNullable(),
      producerPremiumPerCwt: zDecimalNullable(),

      // indemnity fields

      actualEndingValuePerCwt: zDecimalNullable().optional(),
      actualEndingValuePerHead: zDecimalNullable().optional(),
      actualEndingValueTotal: zDecimalNullable().optional(),
      actualIndemnityPerCwt: zDecimalNullable().optional(),
      actualIndemnityPerHead: zDecimalNullable().optional(),
      actualIndemnityTotal: zDecimalNullable().optional(),
      netActualIndemnityPerCwt: zDecimalNullable().optional(),
      netActualIndemnityPerHead: zDecimalNullable().optional(),
      netActualIndemnityTotal: zDecimalNullable().optional(),

      projectedCashEndingValuePerCwt: zDecimalNullable().optional(),
      projectedCashEndingValuePerHead: zDecimalNullable().optional(),
      projectedCashEndingValueTotal: zDecimalNullable().optional(),
      projectedCashIndemnityPerCwt: zDecimalNullable().optional(),
      projectedCashIndemnityPerHead: zDecimalNullable().optional(),
      projectedCashIndemnityTotal: zDecimalNullable().optional(),
      netProjectedCashIndemnityPerCwt: zDecimalNullable().optional(),
      netProjectedCashIndemnityPerHead: zDecimalNullable().optional(),
      netProjectedCashIndemnityTotal: zDecimalNullable().optional(),

      projectedFuturesEndingValuePerCwt: zDecimalNullable().optional(),
      projectedFuturesEndingValuePerHead: zDecimalNullable().optional(),
      projectedFuturesEndingValueTotal: zDecimalNullable().optional(),
      projectedFuturesIndemnityPerCwt: zDecimalNullable().optional(),
      projectedFuturesIndemnityPerHead: zDecimalNullable().optional(),
      projectedFuturesIndemnityTotal: zDecimalNullable().optional(),
      netProjectedFuturesIndemnityPerCwt: zDecimalNullable().optional(),
      netProjectedFuturesIndemnityPerHead: zDecimalNullable().optional(),
      netProjectedFuturesIndemnityTotal: zDecimalNullable().optional(),
    })
  ),
});

export type GetLrpIndemnityResponse = z.infer<
  typeof getLrpIndemnityResponseSchema
>;
