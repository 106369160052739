import { toTitleCase } from 'common'
import { Suspense, useEffect } from 'react'
import { useAppSettings } from '../../contexts'

const defaultTitle = 'Portal'

type RouteElementProps = {
  Element: React.FC
  title?: string
  isRoot?: boolean
  children?: React.ReactNode
}

const RouteElement = ({
  children,
  Element,
  title,
  isRoot,
}: RouteElementProps) => {
  const { appSettings } = useAppSettings()

  useEffect(() => {
    if (!isRoot) {
      const elementName =
        Element.displayName || (Element.name && toTitleCase(Element.name))
      const pageTitle = title || elementName || defaultTitle
      document.title = `${pageTitle} | ${appSettings.name}`
    }
  }, [isRoot, title, Element, appSettings])

  return (
    <Suspense fallback={<>...</>}>
      <Element />
      {children}
    </Suspense>
  )
}

export default RouteElement
