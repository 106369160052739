export * from './auth';
export * from './databases';
export * from './email';
export * from './environment';
export * from './insurance';
export * from './logging';
export * from './redis';
export * from './trading';

// LOCALHOST
export function getFrontendHost(): string {
  return process.env['FRONTEND_HOST'] || 'http://localhost:3000';
}

export function getBackendHost(): string {
  return process.env['BACKEND_HOST'] || 'http://localhost:5000';
}

export function getPort(): number {
  return Number(process.env['PORT']) || 5000;
}

export function getWorkerPort(): number {
  return Number(process.env['WORKER_PORT']) || 9001;
}

export function getHost(): string {
  return process.env['HOST'] || '0.0.0.0';
}

// BARCHART
export function getBarchartDelayedApiToken(): string {
  return process.env['BARCHART_API_TOKEN'] || '';
}

export function getBarchartRealtimeApiToken(): string {
  return process.env['BARCHART_REALTIME_API_TOKEN'] || '';
}

// BOLDSIGN
export function getBoldsignApiKey(): string {
  return process.env['BOLDSIGN_API_KEY'] || '';
}

// HYBISCUS
export function getHybiscusApiKey(): string {
  return process.env['HYBISCUS_API_KEY'] || '';
}

export function getS3BucketForReports(): string {
  return process.env['AWS_REPORTS_BUCKET'] || 'reports-us-east-2-staging1';
}

export function getS3BucketForUploads(): string {
  return process.env['AWS_UPLOADS_BUCKET'] || 'uploads-us-east-2-staging1';
}

// GEOCODIO
export function getGeocodioApiKey(): string {
  return process.env['GEOCODIO_API_KEY'] || '';
}

// FMH
export const getFmhOrigin = () => {
  return process.env['FMH_ORIGIN'];
};

// InsureIQ API
export const getInsureiqApiHost = () => {
  return process.env['INSUREIQ_API_HOST'] || process.env['SGPORTAL_API_HOST'];
};

export const getInsureiqClientId = () => {
  return process.env['INSUREIQ_CLIENT_ID'] || process.env['SGPORTAL_CLIENT_ID'];
};

export function getInsureiqClientSecret(): string | undefined {
  return (
    process.env['INSUREIQ_CLIENT_SECRET'] ||
    process.env['SGPORTAL_CLIENT_SECRET']
  );
}

// Crypto
export function getInsureIQCryptoIV(): string | undefined {
  return process.env['CRYPTO_IV'] || process.env['PORTAL_CRYPTO_IV'];
}

export function getInsureIQCryptoSecret(): string | undefined {
  return process.env['CRYPTO_SECRET'] || process.env['PORTAL_CRYPTO_SECRET'];
}

export function getInsureIQSourceCryptoIV(): string | undefined {
  return process.env['SOURCE_CRYPTO_IV'];
}

export function getInsureIQSourceCryptoSecret(): string | undefined {
  return process.env['SOURCE_CRYPTO_SECRET'];
}

// Contracts
export function getS3FolderForLighthouseContractsFiles(): string | undefined {
  return process.env['S3_FOLDER_FOR_LIGHTHOUSE_CONTRACTS_FILES'];
}

export function isTestRunner(): boolean {
  return !!process.env['JEST_WORKER_ID'] || !!process.env['VITEST_POOL_ID'];
}
