import { legacySequelizeUserSchema } from './shared';
import { z } from 'zod';

export const getUserRequestSchema = z.object({
  search: z.string(),
});
export type GetUserRequest = z.infer<typeof getUserRequestSchema>;

export const getUserResponseSchema = z.object({
  data: z.object({
    users: z.array(
      legacySequelizeUserSchema.omit({ profile: true }).optional()
    ),
  }),
});
export type GetUserResponse = z.infer<typeof getUserResponseSchema>;

export const getUserByIdResponseSchema = z.object({
  user: legacySequelizeUserSchema,
});
export type GetUserByIdResponse = z.infer<typeof getUserByIdResponseSchema>;

export const getUsersRequestSchema = z.object({
  page: z.number().optional(),
  pageSize: z.number().optional(),

  orderBy: z.string().optional(),
  order: z.enum(['asc', 'desc']).optional(),

  search: z.string().optional(),
  roles: z.array(z.string()).optional(),
  origin: z.enum(['admin', 'agent']).optional(),
  agentId: z.string().optional(),
});

export type GetUsersRequest = z.infer<typeof getUsersRequestSchema>;
export const getUsersResponseSchema = z.object({
  data: z.object({
    users: z
      .array(
        legacySequelizeUserSchema.extend({
          agentId: z.string().nullable().optional(),
        })
      )
      .optional(),
    totalCount: z.number().optional(),
  }),
});

export type GetUsersResponse = z.infer<typeof getUsersResponseSchema>;
