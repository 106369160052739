import type { Page } from './Page';
import { IIQRoles } from './Roles';

type PageRecord = Readonly<Record<string, Page>>;

// Type guard for pages with roles
export const hasAccess = (page: Page, userRoles: IIQRoles[]): boolean => {
  if (!page.accessRoles?.length) return true;
  return userRoles.some((role) => page.accessRoles?.includes(role));
};

// RevIQ
const livestockPages = {
  livestock: {
    title: 'Livestock',
    path: '/livestock',
  },
  livestockLots: {
    title: 'Lots',
    path: '/livestock/lots',
  },
  livestockCreateLot: {
    title: 'Create Lot',
    path: '/livestock/lots/create',
  },
  livestockEditLot: {
    title: 'Edit Lot',
    path: '/livestock/lots/:lotId/edit',
  },
  livestockDeleteLot: {
    title: 'Delete Lot',
    path: '/livestock/lots/:lotId/delete',
  },
  livestockLot: {
    title: 'Lot Details',
    path: '/livestock/lots/details/:lotId',
  },
  livestockLotOverview: {
    title: 'Overview',
    path: '/livestock/lots/details/:lotId/overview',
  },
  livestockLotOwnership: {
    title: 'Ownership',
    path: '/livestock/lots/details/:lotId/ownership',
  },
  livestockLotInsurance: {
    title: 'Insurance',
    path: '/livestock/lots/details/:lotId/insurance',
  },
  livestockLotInsuranceType: {
    title: 'Insurance Type',
    path: '/livestock/lots/details/:lotId/insurance/:type',
  },
  livestockLotInsuranceTypeUnit: {
    title: 'Insurance Unit',
    path: '/livestock/lots/details/:lotId/insurance/:type/:unit',
  },
  livestockLotHedging: {
    title: 'Hedging',
    path: '/livestock/lots/details/:lotId/hedging',
  },
  livestockSales: {
    title: 'Sales',
    path: '/livestock/sales',
  },
  livestockSaleLotSelection: {
    title: 'Sale Lot Selection',
    path: '/livestock/sales/lot-selection',
  },
  livestockCreateSale: {
    title: 'Create Sale',
    path: '/livestock/sales/create',
  },
  livestockEditSale: {
    title: 'Edit Sale',
    path: '/livestock/sales/:saleId/edit',
  },
  livestockDeleteSale: {
    title: 'Delete Sale',
    path: '/livestock/sales/:saleId/delete',
  },
  livestockInsurance: {
    title: 'Insurance',
    path: '/livestock/insurance',
  },
  livestockInsuranceType: {
    title: 'Insurance',
    path: '/livestock/insurance/:type',
  },
  livestockInsuranceTypeUnit: {
    title: 'Insurance',
    path: '/livestock/insurance/:type/:unit',
  },
  livestockCreateLrpCoverage: {
    title: 'Add Coverage',
    path: '/livestock/insurance/lrp/create',
  },
  livestockEditLrpCoverage: {
    title: 'Edit Coverage',
    path: '/livestock/insurance/lrp/:coverageId/edit',
  },
  livestockCreateLgmCoverage: {
    title: 'Add Coverage',
    path: '/livestock/insurance/lgm/create',
  },
  livestockEditLgmCoverage: {
    title: 'Edit Coverage',
    path: '/livestock/insurance/lgm/:coverageId/edit',
  },
  livestockDeleteCoverage: {
    title: 'Delete Coverage',
    path: '/livestock/insurance/:type/:coverageId/delete',
  },
} satisfies PageRecord;

const entityPages = {
  entity: {
    title: 'Entities',
    path: '/settings/entities',
  },
  entityCreate: {
    title: 'Create Entity',
    path: '/settings/entities/create',
  },
  entityEdit: {
    title: 'Edit Entity',
    path: '/settings/entities/:entityId/edit',
  },
  entityDelete: {
    title: 'Delete Entity',
    path: '/settings/entities/:entityId/delete',
  },
} satisfies PageRecord;

const dashboardPages = {
  dashboard: {
    title: 'Dashboard',
    path: '/dashboard',
  },
  dashboardCrops: {
    title: 'Crops',
    path: '/dashboard/crops',
  },
  dashboardLivestock: {
    title: 'Livestock',
    path: '/dashboard/livestock',
  },
} satisfies PageRecord;

const advisorHubPages = {
  advisorHub: {
    title: 'Advisor Hub',
    path: '/advisor-hub',
  },
  advisorHubOperations: {
    title: 'Operations',
    path: '/advisor-hub/operations',
  },
  advisorHubCrops: {
    title: 'Crops',
    path: '/advisor-hub/crops',
  },
  advisorHubReports: {
    title: 'Reports',
    path: '/advisor-hub/reports',
  },
  advisorHubClientCropSummaryReport: {
    title: 'Reports',
    path: '/advisor-hub/reports/client-crop-summary',
  },
} satisfies PageRecord;

const cashSales = {
  cashSalesSummary: {
    title: 'Cash Sales Summary',
    path: '/crops/cash-sales/summary',
  },
  cashSalesDetails: {
    title: 'Cash Sales Details',
    path: '/crops/cash-sales/details/:operationCropId',
  },
  cashSalesEditContract: {
    title: 'Edit Contract',
    path: '/crops/cash-sales/details/:operationCropId/edit-contract/:contractId',
  },
  cashSalesSummaryAddContract: {
    title: 'Add Contract',
    path: '/crops/cash-sales/summary/add-contract',
  },
  cashSalesAddContractWithOperationCrop: {
    title: 'Add Contract',
    path: '/crops/cash-sales/details/:operationCropId/add-contract',
  },
  cashSalesEditPricingEvents: {
    title: 'Edit Pricing Events',
    path: '/crops/cash-sales/details/:operationCropId/edit-pricing-events/:contractId',
  },
} satisfies PageRecord;

const settingsPages = {
  settingsRoot: {
    title: 'Settings',
    path: '/settings',
  },
  settingsOperation: {
    title: 'Operation',
    path: '/settings/operation',
  },
  settingsCrops: {
    title: 'Crops',
    path: '/settings/crops',
  },
  settingsCropsAdd: {
    title: 'Crops',
    path: '/settings/crops/add',
  },
  settingsCropsCopy: {
    title: 'Crops',
    path: '/settings/crops/copy',
  },
  settingsCropsEditWithOperationCropId: {
    title: 'Crops',
    path: '/settings/crops/edit/:operationCropId',
  },
  settingsCropsDeleteErrorWithOperationCropId: {
    title: 'Crops',
    path: '/settings/crops/delete-error/:operationCropId',
  },
  settingsCropsConfirmDeleteWithOperationCropId: {
    title: 'Crops',
    path: '/settings/crops/confirm-delete/:operationCropId',
  },
  settingsCosts: {
    title: 'Costs',
    path: '/settings/costs',
  },
  settingsPrices: {
    title: 'Prices',
    path: '/settings/prices',
  },
  settingsPeople: {
    title: 'People',
    path: '/settings/people',
  },
  settingsIntegrations: {
    title: 'Integrations',
    path: '/settings/integrations',
  },
  settingsEntities: {
    title: 'Entities',
    path: '/settings/entities',
  },
};

export const reviqPages = {
  ...livestockPages,
  ...entityPages,
  ...dashboardPages,
  ...advisorHubPages,
  ...cashSales,
  ...settingsPages,
} as const satisfies PageRecord;

// InsureIQ

export const insureiqPages = {
  home: {
    title: 'Home',
    path: '/',
  },
  apply: {
    title: 'Apply for Coverage',
    path: '/insurance/apply',
    accessRoles: [],
  },
  applyBusiness: {
    title: 'Apply for Business Coverage',
    path: '/insurance/apply/business',
    accessRoles: [],
  },
  applyIndividual: {
    title: 'Apply for Individual Coverage',
    path: '/insurance/apply/individual',
    accessRoles: [],
  },
  coverage: {
    title: 'My Coverages',
    path: '/insurance/my-coverages',
    accessRoles: [IIQRoles.AGENT, IIQRoles.CUSTOMER],
  },
  insurance: {
    title: 'Quotes',
    path: '/insurance',
  },
  lrpQuote: {
    title: 'LRP Quote',
    path: '/insurance/lrp-quote',
  },
  lgm: {
    title: 'LGM Quote',
    path: '/insurance/lgm',
  },
  lgmQuote: {
    title: 'LGM Quote',
    path: '/insurance/lgm/quote',
  },
  lgmQuoteScenarioAnalysis: {
    title: 'Scenario Analysis',
    path: '/insurance/lgm/scenario-analysis',
  },
  media: {
    title: 'Media',
    path: '/media',
  },
  faq: {
    title: 'FAQ',
    path: '/faq',
  },
  contactUs: {
    title: 'Contact Us',
    path: '/contact-us',
  },
  signIn: {
    title: 'Sign In',
    path: '/account/signin',
  },
  signUp: {
    title: 'Sign Up',
    path: '/account/signup',
  },
  signUpComplete: {
    title: 'Confirm Sign Up',
    path: '/account/signup-complete',
  },
  sendLoginLink: {
    title: 'Get Login Link',
    path: '/account/send-login-link',
  },
  forgotPassword: {
    title: 'Forgot Password',
    path: '/account/forgot-password',
  },
  resetPassword: {
    title: 'Reset Password',
    path: '/account/reset-password',
  },
  confirmAccount: {
    title: 'Confirm Account',
    path: '/account/confirm-account',
  },
  profile: {
    title: 'My Profile',
    path: '/profile',
    accessRoles: [],
  },
  settings: {
    title: 'Application Settings',
    path: '/settings',
    accessRoles: [IIQRoles.ADMIN],
  },
  termsAndConditions: {
    title: 'Terms & Conditions',
    path: '/terms-and-conditions',
  },
  lrpTerms: {
    title: 'Terms & Conditions',
    path: '/terms-and-conditions/lrp',
  },
  breakevenCalculator: {
    title: 'Breakeven Calculator',
    path: '/breakeven-calculator',
  },
  apiKeys: {
    title: 'API Keys',
    path: '/api-keys',
    accessRoles: [IIQRoles.PARTNER],
  },
  watchlist: {
    title: 'Watchlist',
    path: '/insurance/watchlist',
    accessRoles: [IIQRoles.AGENT, IIQRoles.CUSTOMER],
  },
  manageUsers: {
    title: 'Manage Users',
    path: '/settings/manage-users',
    accessRoles: [IIQRoles.ADMIN, IIQRoles.AGENT],
  },
  tools: {
    title: 'Tools',
    path: '/settings/tools',
    accessRoles: [IIQRoles.SUPER_ADMIN],
  },
  appSettings: {
    title: 'App Settings',
    path: '/settings/app',
    accessRoles: [IIQRoles.ADMIN],
  },
  aipSettings: {
    title: 'AIP Settings',
    path: '/settings/aip',
    accessRoles: [IIQRoles.ADMIN],
  },
  agentDashboard: {
    title: 'Agent Hub',
    path: '/agent',
    accessRoles: [IIQRoles.AGENT],
  },
  agentCustomers: {
    title: 'Users',
    path: '/agent/customers',
    accessRoles: [IIQRoles.AGENT],
  },
  agentPolicyManagement: {
    title: 'Policy Management',
    path: '/agent/policies',
    accessRoles: [IIQRoles.AGENT],
  },
  agentCoverageManagement: {
    title: 'Coverage Management',
    path: '/agent/coverages',
    accessRoles: [IIQRoles.AGENT],
  },
  agentCampaignManager: {
    title: 'Quote Scheduler (Beta)',
    path: '/agent/campaign-manager',
    accessRoles: [IIQRoles.AGENT],
  },
  agentEntitiesManagement: {
    title: 'Entities',
    path: '/agent/entities',
    accessRoles: [IIQRoles.AGENT],
  },
  agentEntityCreate: {
    title: 'Create Entity',
    path: '/agent/entities/create',
    accessRoles: [IIQRoles.AGENT],
  },
  agentEntityView: {
    title: 'View Entity',
    path: '/agent/entities/:entityId',
    accessRoles: [IIQRoles.AGENT],
  },
  agentEntityEdit: {
    title: 'Edit Entity',
    path: '/agent/entities/:entityId/:action',
    accessRoles: [IIQRoles.AGENT],
  },
  agentPolicyCreate: {
    title: 'Create Policy',
    path: '/agent/policies/create',
    accessRoles: [IIQRoles.AGENT],
  },
  agentPolicyView: {
    title: 'View Policy',
    path: '/agent/policies/:policyId',
    accessRoles: [IIQRoles.AGENT],
  },
  agentPolicyEdit: {
    title: 'Edit Policy',
    path: '/agent/policies/:policyId/:action',
    accessRoles: [IIQRoles.AGENT],
  },
  admin: {
    title: 'Admin',
    path: '/admin/',
    accessRoles: [IIQRoles.ADMIN],
  },
  adminPolicyManagement: {
    title: 'Policy Management',
    path: '/admin/policies',
    accessRoles: [IIQRoles.AGENT],
  },
  adminCoverageManagement: {
    title: 'Coverage Management',
    path: '/admin/coverages',
    accessRoles: [IIQRoles.AGENT],
  },
  orgs: {
    title: 'Orgs',
    path: '/settings/orgs',
    accessRoles: [IIQRoles.SUPER_ADMIN],
  },
  unsubscribeCampaign: {
    title: 'Unsubscribe',
    path: '/unsubscribe/campaign/:campaignId/:userId',
  },
} as const satisfies PageRecord;

// Add utility types
export type ReviqPageKey = keyof typeof reviqPages;
export type InsureiqPageKey = keyof typeof insureiqPages;

// Type-safe path getters
export const getPagePath = <T extends PageRecord>(
  pages: T,
  key: keyof T
): string => pages[key].path;

export const getPageTitle = <T extends PageRecord>(
  pages: T,
  key: keyof T
): string => pages[key].title;
