import localDayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import minMax from 'dayjs/plugin/minMax';

localDayjs.extend(utc);
localDayjs.extend(timezone);
localDayjs.extend(customParseFormat);
localDayjs.extend(isBetween);
localDayjs.extend(isSameOrBefore);
localDayjs.extend(isSameOrAfter);
localDayjs.extend(minMax);

export function nowUtc(): Date {
  return nowUtcDayjs().toDate();
}

// Mock dayjs.utc to mock all "now" datetimes see test file
export function nowUtcDayjs(): Dayjs {
  return localDayjs.utc();
}

export function nowCdt(): Date {
  return nowCdtDayjs().toDate();
}

export function nowCdtDayjs(): Dayjs {
  return nowUtcDayjs().tz('America/Chicago');
}

export function dayjsCdt(date: localDayjs.ConfigType): Dayjs {
  return localDayjs.tz(date, 'America/Chicago');
}

export function isValidYear(year: number): boolean {
  const date = localDayjs(`${year}-01-01`, 'YYYY-MM-DD', true);
  return date.isValid();
}

// fun thing to make Zod happy
// z.instanceof(DayjsCtor)
export const DayjsCtor = localDayjs as unknown as typeof Dayjs;

export const diffInWeeks = (fromDate: Dayjs, toDate: Dayjs) => {
  return toDate.diff(fromDate, 'week');
};

export const makeSceEndDate = (
  salesEffectiveDate: localDayjs.ConfigType,
  endorsementLength: number
): string => {
  const endDate = dayjsCdt(salesEffectiveDate)
    .add(endorsementLength, 'week')
    .format('YYYY-MM-DD');
  return endDate;
};

export { Dayjs, localDayjs as dayjs };
