import {
  InsureIQLrpCommodityTypeNames,
  RmaLrpCommodityTypeCodes,
  InsureIQCoverageStatus,
  InsureIQPolicyTypes,
  InsureIQLrpCommodityNames,
  RmaAipCodes,
} from '@harvestiq/constants';
import { GeneratedAlways, Selectable, Insertable, Updateable } from 'kysely';
import {
  LrpCoverageDetails,
  lrpCoverageDetailsSchema,
} from './coverage-details';
import { dbSharedEndorsementSchema } from './shared';
import { z } from 'zod';
import { zDate, zDecimalNullable } from '@harvestiq/zod';
import { expectTypeOf } from 'expect-type';
import {
  DbColumnDecimalNullable,
  DbTimestampGenerated,
  DbTimestampNullable,
} from '@harvestiq/utils';

/**
 * Represents an eventual LRP Endorsement table in the database.
 */
export type LrpEndorsementsTable = {
  // Common fields
  id: GeneratedAlways<string>;
  agentSignedAt: DbTimestampNullable;
  signingAgentId: string | null;
  createdBy: string | null;
  customerSignedAt: DbTimestampNullable;
  externalStatus: string | null;
  isExternallyManaged: boolean;
  reinsuranceYear: number;
  policyId: string | null;
  salesEffectiveDate: string;
  status: InsureIQCoverageStatus;
  submittedToAipAt: DbTimestampNullable;
  entityHistoryId: string | null;
  aipCode: RmaAipCodes | null;
  importSourceId: string | null;
  canceledByImportSourceId: string | null;
  totalPremium: DbColumnDecimalNullable;
  subsidyTotal: DbColumnDecimalNullable;
  producerPremiumTotal: DbColumnDecimalNullable;
  watchedAt: DbTimestampNullable;
  createdAt: DbTimestampGenerated;
  updatedAt: DbTimestampGenerated;
  deletedAt: DbTimestampNullable;
  statusUpdatedAt: DbTimestampNullable;
  premiumUpdatedAt: DbTimestampNullable;
  indemnityUpdatedAt: DbTimestampNullable;

  // LRP fields
  commodity: InsureIQLrpCommodityNames;
  type: InsureIQPolicyTypes;
  commodityType: InsureIQLrpCommodityTypeNames;
  commodityTypeCode: RmaLrpCommodityTypeCodes | null;
  details: LrpCoverageDetails;
  liabilityAmountTotal: DbColumnDecimalNullable;
  liabilityAmountPerCwt: DbColumnDecimalNullable;
  liabilityAmountPerHead: DbColumnDecimalNullable;
  livestockRate: DbColumnDecimalNullable;
  expectedEndingValueTotal: DbColumnDecimalNullable;
  expectedEndingValuePerCwt: DbColumnDecimalNullable;
  expectedEndingValuePerHead: DbColumnDecimalNullable;
  actualEndingValueTotal: DbColumnDecimalNullable;
  actualEndingValuePerCwt: DbColumnDecimalNullable;
  actualEndingValuePerHead: DbColumnDecimalNullable;
  actualIndemnityTotal: DbColumnDecimalNullable;
  actualIndemnityPerCwt: DbColumnDecimalNullable;
  actualIndemnityPerHead: DbColumnDecimalNullable;
  netActualIndemnityTotal: DbColumnDecimalNullable;
  netActualIndemnityPerCwt: DbColumnDecimalNullable;
  netActualIndemnityPerHead: DbColumnDecimalNullable;
  projectedCashEndingValueTotal: DbColumnDecimalNullable;
  projectedCashEndingValuePerCwt: DbColumnDecimalNullable;
  projectedCashEndingValuePerHead: DbColumnDecimalNullable;
  projectedCashIndemnityTotal: DbColumnDecimalNullable;
  projectedCashIndemnityPerCwt: DbColumnDecimalNullable;
  projectedCashIndemnityPerHead: DbColumnDecimalNullable;
  netProjectedCashIndemnityTotal: DbColumnDecimalNullable;
  netProjectedCashIndemnityPerCwt: DbColumnDecimalNullable;
  netProjectedCashIndemnityPerHead: DbColumnDecimalNullable;
  projectedFuturesEndingValueTotal: DbColumnDecimalNullable;
  projectedFuturesEndingValuePerCwt: DbColumnDecimalNullable;
  projectedFuturesEndingValuePerHead: DbColumnDecimalNullable;
  projectedFuturesIndemnityTotal: DbColumnDecimalNullable;
  projectedFuturesIndemnityPerCwt: DbColumnDecimalNullable;
  projectedFuturesIndemnityPerHead: DbColumnDecimalNullable;
  netProjectedFuturesIndemnityTotal: DbColumnDecimalNullable;
  netProjectedFuturesIndemnityPerCwt: DbColumnDecimalNullable;
  netProjectedFuturesIndemnityPerHead: DbColumnDecimalNullable;
  aipLrpPremiumKey: string | null;
  coverageLevel: DbColumnDecimalNullable;
  coveragePriceTotal: DbColumnDecimalNullable;
  coveragePricePerCwt: DbColumnDecimalNullable;
  coveragePricePerHead: DbColumnDecimalNullable;
  endorsementLength: DbColumnDecimalNullable;
  headCount: DbColumnDecimalNullable;
  producerPremiumPerCwt: DbColumnDecimalNullable;
  producerPremiumPerHead: DbColumnDecimalNullable;
  subsidyPerCwt: DbColumnDecimalNullable;
  subsidyPerHead: DbColumnDecimalNullable;
  subsidyRate: DbColumnDecimalNullable;
  targetWeightQuantityCwt: DbColumnDecimalNullable;
  totalPremiumPerCwt: DbColumnDecimalNullable;
  totalPremiumPerHead: DbColumnDecimalNullable;

  // LGM fields
  // TODO: Remove once we have two separate tables
  lgmIndemnityDetails: never;
  aipLgmPremiumKey: never;
  liveCattleTargetCwtPerHead: never;
  feederCattleTargetCwtPerHead: never;
  cornTargetBuPerHead: never;
  deductible: never;
  targetMarketingsTotal: never;
  expectedGrossMarginTotal: never;
  grossMarginGuaranteeTotal: never;
  lgmGrossMarginChangeTotal: never;
  lgmActualGrossMarginTotal: never;
  lgmActualIndemnity: never;
  lgmNetActualIndemnity: never;
  lgmProjectedGrossMarginTotal: never;
  lgmProjectedIndemnity: never;
  lgmNetProjectedIndemnity: never;
};

export type DbLrpEndorsement = Selectable<LrpEndorsementsTable>;
export type DbLrpEndorsementInsert = Insertable<LrpEndorsementsTable>;
export type DbLrpEndorsementUpdate = Updateable<LrpEndorsementsTable>;

export const dbLrpEndorsementSchema = dbSharedEndorsementSchema.extend({
  commodity: z.nativeEnum(InsureIQLrpCommodityNames),
  type: z.literal(InsureIQPolicyTypes.LRP),
  commodityType: z.nativeEnum(InsureIQLrpCommodityTypeNames),
  commodityTypeCode: z.nativeEnum(RmaLrpCommodityTypeCodes).nullable(),
  details: lrpCoverageDetailsSchema,
  liabilityAmountTotal: zDecimalNullable(),
  liabilityAmountPerCwt: zDecimalNullable(),
  liabilityAmountPerHead: zDecimalNullable(),
  livestockRate: zDecimalNullable(),
  expectedEndingValueTotal: zDecimalNullable(),
  expectedEndingValuePerCwt: zDecimalNullable(),
  expectedEndingValuePerHead: zDecimalNullable(),
  actualEndingValueTotal: zDecimalNullable(),
  actualEndingValuePerCwt: zDecimalNullable(),
  actualEndingValuePerHead: zDecimalNullable(),
  actualIndemnityTotal: zDecimalNullable(),
  actualIndemnityPerCwt: zDecimalNullable(),
  actualIndemnityPerHead: zDecimalNullable(),
  netActualIndemnityTotal: zDecimalNullable(),
  netActualIndemnityPerCwt: zDecimalNullable(),
  netActualIndemnityPerHead: zDecimalNullable(),
  projectedCashEndingValueTotal: zDecimalNullable(),
  projectedCashEndingValuePerCwt: zDecimalNullable(),
  projectedCashEndingValuePerHead: zDecimalNullable(),
  projectedCashIndemnityTotal: zDecimalNullable(),
  projectedCashIndemnityPerCwt: zDecimalNullable(),
  projectedCashIndemnityPerHead: zDecimalNullable(),
  netProjectedCashIndemnityTotal: zDecimalNullable(),
  netProjectedCashIndemnityPerCwt: zDecimalNullable(),
  netProjectedCashIndemnityPerHead: zDecimalNullable(),
  projectedFuturesEndingValueTotal: zDecimalNullable(),
  projectedFuturesEndingValuePerCwt: zDecimalNullable(),
  projectedFuturesEndingValuePerHead: zDecimalNullable(),
  projectedFuturesIndemnityTotal: zDecimalNullable(),
  projectedFuturesIndemnityPerCwt: zDecimalNullable(),
  projectedFuturesIndemnityPerHead: zDecimalNullable(),
  netProjectedFuturesIndemnityTotal: zDecimalNullable(),
  netProjectedFuturesIndemnityPerCwt: zDecimalNullable(),
  netProjectedFuturesIndemnityPerHead: zDecimalNullable(),
  aipLrpPremiumKey: z.string().nullable(),
  coverageLevel: zDecimalNullable(),
  coveragePriceTotal: zDecimalNullable(),
  coveragePricePerCwt: zDecimalNullable(),
  coveragePricePerHead: zDecimalNullable(),
  endorsementLength: zDecimalNullable(),
  headCount: zDecimalNullable(),
  producerPremiumPerCwt: zDecimalNullable(),
  producerPremiumPerHead: zDecimalNullable(),
  subsidyPerCwt: zDecimalNullable(),
  subsidyPerHead: zDecimalNullable(),
  subsidyRate: zDecimalNullable(),
  targetWeightQuantityCwt: zDecimalNullable(),
  totalPremiumPerCwt: zDecimalNullable(),
  totalPremiumPerHead: zDecimalNullable(),
});

expectTypeOf<keyof DbLrpEndorsement>().toEqualTypeOf<
  keyof z.output<typeof dbLrpEndorsementSchema>
>();

export const dbLrpEndorsementInsertSchema = dbLrpEndorsementSchema
  .omit({
    id: true,
    createdAt: true,
  })
  .extend({
    agentSignedAt: zDate().nullable(),
    // Insert as string or date
    customerSignedAt: zDate().nullable(),
    submittedToAipAt: zDate().nullable(),
    watchedAt: zDate().nullable(),
    createdAt: zDate(),
    updatedAt: zDate(),
    deletedAt: zDate().nullable(),
  });

// keys match
expectTypeOf<keyof DbLrpEndorsementInsert>().toEqualTypeOf<
  keyof z.output<typeof dbLrpEndorsementInsertSchema>
>();

export const dbLrpEndorsementUpdateSchema =
  dbLrpEndorsementInsertSchema.partial();

// keys match
expectTypeOf<keyof DbLrpEndorsementUpdate>().toEqualTypeOf<
  keyof z.output<typeof dbLrpEndorsementUpdateSchema>
>();
