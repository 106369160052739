import { z } from 'zod';
import { legacySequelizeUserSchema } from './shared';

export const confirmAccountRequestSchema = z.object({
  code: z.string(),
});
export type ConfirmAccountRequest = z.infer<typeof confirmAccountRequestSchema>;

export const confirmAccountResponseSchema = z.object({
  user: legacySequelizeUserSchema,
});

export type ConfirmAccountResponse = z.infer<
  typeof confirmAccountResponseSchema
>;
