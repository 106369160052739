import { isNullOrUndefined } from 'common'

interface LocalStorage {
  set: <T>(key: string, value: T) => void
  get: <T>(key: string) => T | null
  remove: (key: string) => void
  clear: () => void
}

const localStorageCache: Record<string, unknown> = {}

export const localStorage: LocalStorage = {
  set: <T>(key: string, value: T): void => {
    if (isNullOrUndefined(value)) {
      return localStorage.remove(key)
    }
    localStorageCache[key] = value
    window.localStorage.setItem(key, JSON.stringify(value))
  },

  get: <T>(key: string): T | null => {
    if (!isNullOrUndefined(localStorageCache[key])) {
      return localStorageCache[key] as T
    }
    const localStorageValue = window.localStorage.getItem(key)
    try {
      localStorageCache[key] = JSON.parse(localStorageValue ?? 'null')
    } catch (e) {
      localStorageCache[key] = localStorageValue
    }
    return localStorageCache[key] as T
  },

  remove: (key: string): void => {
    delete localStorageCache[key]
    window.localStorage.removeItem(key)
  },

  clear: (): void => window.localStorage.clear(),
}
