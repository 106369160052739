import { useMemo } from 'react'
import { useUser } from '../contexts'
import { IIQRoles } from '@harvestiq/constants'
import type { Page } from '@harvestiq/constants'

export type MenuItem = Page & {
  children?: MenuItem[]
}

// TODO: Refactor and simplify this function
const filterItems = ({
  user,
  items,
  hasRoles,
}: {
  user: { isConfirmed?: boolean } | null
  items: MenuItem[]
  hasRoles: (roles: IIQRoles[]) => boolean
}): MenuItem[] => {
  const filterItem = (item: MenuItem): MenuItem | false => {
    if (item.children) {
      item.children = filterItems({ user, items: item.children, hasRoles })
    }

    if (item.accessRoles) {
      if (!user?.isConfirmed) {
        return false
      }

      return hasRoles([...item.accessRoles]) ? item : false
    }

    return item
  }

  return items.reduce<MenuItem[]>((acc, item) => {
    const filteredItem = filterItem(item)
    if (filteredItem) {
      acc.push(filteredItem)
    }
    return acc
  }, [])
}

export const useMenuItems = (menuItems: MenuItem[]) => {
  const { user, hasRoles } = useUser()

  return useMemo(
    () => filterItems({ user, items: menuItems, hasRoles }),
    [user, menuItems, hasRoles],
  )
}

export default useMenuItems
