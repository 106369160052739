export enum CampaignStatus {
  ACTIVE = 'ACTIVE', // currently running campaign
  PAUSED = 'PAUSED', // paused by user (would be running)
  SCHEDULED = 'SCHEDULED', // scheduled to start in future
  EXPIRED = 'EXPIRED', // expired or ended
}

// TODO: verify options
export enum CampaignFrequency {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
}

export enum CampaignRecipientStatus {
  ACTIVE = 'ACTIVE',
  UNSUBSCRIBED = 'UNSUBSCRIBED',
  INVALID = 'INVALID', // contact info invalid
}

export enum CampaignCoverageTier {
  ALL = 'ALL',
  TOP = 'TOP',
}

export enum FrequencyDayOfWeek {
  MONDAY = 'Monday',
  TUESDAY = 'Tuesday',
  WEDNESDAY = 'Wednesday',
  THURSDAY = 'Thursday',
  FRIDAY = 'Friday',
}

export const DayOfWeekIndexMap = {
  [FrequencyDayOfWeek.MONDAY]: 1,
  [FrequencyDayOfWeek.TUESDAY]: 2,
  [FrequencyDayOfWeek.WEDNESDAY]: 3,
  [FrequencyDayOfWeek.THURSDAY]: 4,
  [FrequencyDayOfWeek.FRIDAY]: 5,
};

export const DayOfWeekIndexToNameMap = Object.entries(DayOfWeekIndexMap).reduce(
  (acc, [key, value]) => {
    acc[value] = key;
    return acc;
  },
  {} as Record<number, string>
);

export enum CampaignTimeframeTypes {
  ONGOING = 'ONGOING',
  FIXED = 'FIXED',
}

export const CampaignCoverageTierOptions = [
  { value: CampaignCoverageTier.TOP, label: 'Top Level' },
  { value: CampaignCoverageTier.ALL, label: 'All Levels' },
];

export const InsureIQQuoteTableHeaderTexts = {
  LRP: {
    title: 'Livestock Risk Protection (LRP) Quote',
    columnHeaders: [
      'Marketing Date',
      'Coverage Level',
      'Coverage Price (/cwt)',
      'Coverage Premium (/cwt)',
    ],
    columnHeadersExpanded: [
      'Endorsement<br />Length (weeks)',
      'End Date',
      'Expected<br />Value (/cwt)',
      'Coverage<br />Price (/cwt)',
      'Coverage<br />Level',
      'Rate',
      'Insured<br />Value',
      'Total<br />Premium',
      'Subsidy',
      'Producer<br />Premium',
      'Producer<br />Premium (/cwt)',
      'Producer<br />Premium (/head)',
      'CME Put<br />Strike',
      'CME Put<br />Premium (/cwt)',
      'Futures<br />Contract Month',
    ],
    uom: '',
  },
  LGM: {
    title: 'Livestock Gross Margin (LGM) Quote',
    columnHeaders: [
      'Marketing Month',
      'Deductible',
      'Gross Margin Guarantee',
      'Producer Premium',
    ],
    uom: '($/head)',
  },
};

export const CampaignMarketingMonthsAll: number[] = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11,
];

export const CampaignMarketingLengthsCattle: number[] = [
  2, 3, 4, 5, 6, 7, 8, 9, 10, 11,
];

export const CampaignMarketingLengthsSwine: number[] = [2, 3, 4, 5, 6];
