import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import Joyride, { EVENTS, ACTIONS } from 'react-joyride'
import { useLocation } from 'react-router-dom'
import { defaultColorScheme } from '@harvestiq/iiq/common'
import { useAppSettings } from '../AppSettingsProvider'
import { useUser } from '../User/user-context'
import { findTour } from './tours'

const { STEP_AFTER, TOUR_END } = EVENTS
const { SKIP, CLOSE } = ACTIONS

const TourContext = React.createContext()
const { Provider } = TourContext

export const useTour = () => useContext(TourContext)

const Tour = ({ steps, props, styles }) => (
  <Joyride
    {...{
      steps,
      styles,
      ...props,
    }}
  />
)

const TourProvider = ({ children }) => {
  const { appSettings, isMobile } = useAppSettings()
  const { user, getMe, isUserProfileComplete } = useUser()
  const { pathname } = useLocation()
  const [activeTourId, setActiveTourId] = useState(null)
  const [tourProps, setTourPropsState] = useState({})
  const [tourSteps, setTourSteps] = useState([])
  const { colorScheme = defaultColorScheme } = appSettings

  const setTourProps = useCallback(
    props =>
      setTourPropsState(prevTourProps => ({
        ...prevTourProps,
        ...props,
      })),
    [setTourPropsState],
  )

  const setStepIndex = useCallback(
    index => setTourProps({ stepIndex: index }),
    [setTourProps],
  )

  const tourConditions = useMemo(
    () => ({
      user,
      isUserProfileComplete,
      pathname,
    }),
    [user, pathname, isUserProfileComplete],
  )

  const endTour = useCallback(async () => {
    await getMe()
    setActiveTourId(null)
    setTourPropsState({ steps: [], run: false })
  }, [setActiveTourId, setTourPropsState, getMe])

  const tourArgs = useMemo(
    () => ({
      user,
      setTourProps,
      setStepIndex,
      endTour,
      ...appSettings,
      isMobile,
      isSkipped: ({ type, action }) =>
        (type === STEP_AFTER && action === CLOSE) ||
        (type === TOUR_END && action === SKIP),
    }),
    [user, setTourProps, setStepIndex, endTour, appSettings],
  )

  const setTour = useCallback(
    (tourId, tour) => {
      if (tourId === activeTourId) {
        return
      }
      const { steps, ...props } = tour(tourArgs)
      setActiveTourId(tourId)
      setTourProps({ steps, ...props })
    },
    [activeTourId, setActiveTourId, setTourProps, tourArgs],
  )

  useEffect(() => {
    if (!activeTourId) {
      const [tourId, tour] = findTour(tourConditions)

      if (tourId) {
        setTour(tourId, tour)
      }
    }
  }, [tourConditions, activeTourId, setTour])

  return (
    <Provider
      value={{
        setTourSteps,
        setTourProps,
        setTourId: setActiveTourId,
        activeTourId,
        setStepIndex,
        endTour,
      }}
    >
      <Tour
        steps={tourSteps}
        props={tourProps}
        styles={{
          options: {
            primaryColor: colorScheme.primary,
          },
        }}
      />
      {children}
    </Provider>
  )
}

export { TourContext, TourProvider }
